import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CrossIconMobile = ({ width = '48', height = '48' }) => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' || currentTheme === 'navy' ? '#a8a8a8' : '#6f6f6f';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 18.4L29.6 17L24 22.6L18.4 17L17 18.4L22.6 24L17 29.6L18.4 31L24 25.4L29.6 31L31 29.6L25.4 24L31 18.4Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CrossIconMobile;
