// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oSAG3g1aoMGb4dWoj2pb {
  font-size: 13px;
  font-weight: 500;
  border-radius: 2px;
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oZkM5hXPQfHi2Ty0cX0N {
  background: var(--high-importance-bg-color);
  color: var(--high-importance-text-color);
}

.gten8ORJXctc06KV2pIg {
  background: var(--low-importance-bg-color);
  color: var(--low-importance-text-color);
}

.PIiwJK2Ogqqvzkg1e6Q8 {
  background: var(--medium-importance-bg-color);
  color: var(--medium-importance-text-color);
}

.WxP6ynaxonz6bTZ7yiPO {
  font-size: 11px !important;
  line-height: 15px !important;
  text-align: center !important;
  font-weight: 500;
  width: fit-content !important;
  padding: 2px 4px;
  border-radius: 4px !important;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/Global/ImportanceBadge.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,2CAA2C;EAC3C,wCAAwC;AAC1C;;AAEA;EACE,0CAA0C;EAC1C,uCAAuC;AACzC;;AAEA;EACE,6CAA6C;EAC7C,0CAA0C;AAC5C;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;AAC3B","sourcesContent":[".badgeImportance {\n  font-size: 13px;\n  font-weight: 500;\n  border-radius: 2px;\n  width: 20px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.highImp {\n  background: var(--high-importance-bg-color);\n  color: var(--high-importance-text-color);\n}\n\n.lowImp {\n  background: var(--low-importance-bg-color);\n  color: var(--low-importance-text-color);\n}\n\n.medImp {\n  background: var(--medium-importance-bg-color);\n  color: var(--medium-importance-text-color);\n}\n\n.bulkAlertImp {\n  font-size: 11px !important;\n  line-height: 15px !important;\n  text-align: center !important;\n  font-weight: 500;\n  width: fit-content !important;\n  padding: 2px 4px;\n  border-radius: 4px !important;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeImportance": `oSAG3g1aoMGb4dWoj2pb`,
	"highImp": `oZkM5hXPQfHi2Ty0cX0N`,
	"lowImp": `gten8ORJXctc06KV2pIg`,
	"medImp": `PIiwJK2Ogqqvzkg1e6Q8`,
	"bulkAlertImp": `WxP6ynaxonz6bTZ7yiPO`
};
export default ___CSS_LOADER_EXPORT___;
