const AlertEnabled = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.51 20.76C10.51 21.86 11.4 22.75 12.5 22.75C13.6 22.75 14.49 21.86 14.49 20.76H10.51ZM12.5 1.25C11.67 1.25 11 1.92 11 2.75V3.92C7.86 4.6 5.5 7.4 5.5 10.75V16.75L3.5 18.75V19.75H21.5V18.75L19.5 16.75V10.75C19.5 7.4 17.14 4.6 14 3.92V2.75C14 1.92 13.33 1.25 12.5 1.25Z"
        fill="#E61E1E"
      />
      <rect x="8" y="11" width="9" height="2" fill="white" />
    </svg>
  );
};

export default AlertEnabled;
