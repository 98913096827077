import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CancelSearchIos = ({ width = '30', height = '31' }) => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#fff' : '#000';
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.70711 7.04289C7.31658 6.65237 6.68342 6.65237 6.29289 7.04289C5.90237 7.43342 5.90237 8.06658 6.29289 8.45711L10.5858 12.75L6.29289 17.0429C5.90237 17.4334 5.90237 18.0666 6.29289 18.4571C6.68342 18.8476 7.31658 18.8476 7.70711 18.4571L12 14.1642L16.2929 18.4571C16.6834 18.8476 17.3166 18.8476 17.7071 18.4571C18.0976 18.0666 18.0976 17.4334 17.7071 17.0429L13.4142 12.75L17.7071 8.45711C18.0976 8.06658 18.0976 7.43342 17.7071 7.04289C17.3166 6.65237 16.6834 6.65237 16.2929 7.04289L12 11.3358L7.70711 7.04289Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CancelSearchIos;
