import Info from '../../../../assets/svgs/Info';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';
import styles from './AlertInput.module.css';
import { useAlerts } from '../../../../hooks/useAlerts';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

const AlertSettingOptions = ({ settingsKeys, updateShowInfo, data = {} }) => {
  const {
    userAlertPreference,
    updateUserAlertPreferences,
    showDefaultSettingsMobile,
    alertsSettings,
  } = useAlerts();
  const { isIos, isAndroid, isIpad } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid || isIpad;
  const { t } = useTranslation();

  const preferenceData =
    Object.keys(data).length > 0
      ? {
          alertMe: [],
          // @ts-ignore
          alertPlatforms: data?.notificationChannel?.map((item) => {
            return item === 'PUSH' ? 'Push notification' : 'Web platform';
          }),
          // @ts-ignore
          alertRepeatitions: data?.reoccurring ? 'Every time' : 'Only once',
        }
      : userAlertPreference;

  const { alertMe, alertPlatforms, alertRepeatitions } = preferenceData;

  const { getTooltipProps, setTooltipRef, setTriggerRef, getArrowProps, visible } =
    usePopperTooltip({ trigger: 'hover', placement: 'right' });

  return (
    <div className={notWebsite ? style.alertOptionsWrapper : style.alertOptionsWeb}>
      {settingsKeys?.map((item, index) => {
        return (
          <div className={notWebsite ? style.indAlertBoxMobile : style.indAlertBox} key={item}>
            <p className={notWebsite ? style.alertTextMobile : style.alertText}>
              {t(alertSettingsConfigMobile[item].text)}
            </p>
            {index === 0 && (showDefaultSettingsMobile || !notWebsite) && (
              <button
                className={!notWebsite ? style.info : style.infoMobile}
                ref={index === 0 && !notWebsite ? setTriggerRef : null}
                onClick={notWebsite ? () => updateShowInfo(true) : undefined}
              >
                {notWebsite ? <Info /> : <Info width="18" height="18" />}
              </button>
            )}

            {!notWebsite && visible && index === 0 && (
              <div
                className={styles.infoPopUpWeb}
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-alert' })}
              >
                <div {...getArrowProps({ className: 'tooltipArrow' })} />
                {t(
                  'Pre-event warning applicable to macro-economic events only, as earnings are not announced at an exact pre-arranged time (only pre-open, after-close etc.)',
                )}
              </div>
            )}

            <div className={notWebsite ? style.alertItemsMobile : style.alertItems}>
              {alertsSettings &&
                alertSettingsConfigMobile[item]?.options?.map((option) => {
                  let checked = false;

                  if (item === 'alertMe') {
                    checked = alertMe?.includes(option);
                  } else if (item === 'alertPlatforms') {
                    checked = alertPlatforms?.includes(option);
                  } else if (item === 'alertRepeatitions') {
                    checked =
                      (option === 'Every time' && alertRepeatitions === 'Every time') ||
                      (option === 'Only once' && alertRepeatitions === 'Only once');
                  }
                  return (
                    <div
                      key={option}
                      className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection} ${notWebsite ? (isIos ? styles.inputStylingMobile : styles.inputStylingAndroid) : styles.inputStyling}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className={notWebsite ? style.inputWrapperMobile : style.inputWrapper}>
                        <input
                          type={item !== 'alertRepeatitions' ? 'checkbox' : 'radio'}
                          id={option}
                          name={item !== 'alertRepeatitions' ? option : item}
                          value={option}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => updateUserAlertPreferences(option)}
                          checked={checked}
                        />
                      </div>
                      <label
                        htmlFor={option}
                        className={`${notWebsite ? style.labelTextMobile : style.labelText} ${isAndroid ? style.labelTextMobileAndroid : style.none}`}
                      >
                        {t(option)}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AlertSettingOptions;
