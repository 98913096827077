import ImportanceFilterBox from '../../../Filters/ImportanceFilterBox';
import style from './BulkAlertSettings.module.css';
import styles from './BulkAlertsTabs.module.css';
import BulkAlertsSearchCompany from './BulkAlertsSearchCompany';
import { useEffect, useState } from 'react';
import { continents, findContinentbyCountry, getCountryCode } from '../../../../utils/util';
import { useEconCalendar } from '../../../../hooks/useEconCalendar';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import AlertCountryFilter from './AlertCountryFilter';
import { accounts } from '../../../../utils/accountRegions';
import { countryCodeToNameMap } from '../../../../utils/countryCodeNameMapping';
import CustomDateInputWrapper from './CustomDateInputWrapper';
import { useTranslation } from 'react-i18next';

const importanceMap = {
  low: [0, 1],
  medium: [2],
  high: [3],
};
export default function BulkAlertsTabs({
  selectedImportance,
  extendedImportanceFilter,
  setExtendedImportanceFilter,
  handleImportance,
  allCountry,
  setAllCountry,
  selectedAlertType,
  showBulkAlertsDrawer,
  setShowBulkAlertSettingWarning,
  formatDate,
  companyName,
  setCompanyName,
  selectedDate,
  setSelectedDate,
}) {
  const { rawData } = useEconCalendar();
  const { isAndroid, isIos, isIpad } = useEconCalendarParams();
  const { igCompanySiteId } = useSessionData();
  const [filteredBulkAlertsData, setFilteredAlertsData] = useState([]);
  const notWebsite = isAndroid || isIos;
  const { t } = useTranslation();

  const handleCompanySelected = (name) => {
    setCompanyName(name);
  };

  useEffect(() => {
    if (
      (selectedImportance && selectedImportance.length > 0) ||
      (allCountry && allCountry.length > 0)
    ) {
      const filteredResponse =
        rawData &&
        rawData.length > 0 &&
        rawData.filter((item) => {
          const importanceMatch =
            selectedImportance?.length > 0
              ? selectedImportance.map((imp) => importanceMap[imp].includes(item.importance))
              : true;

          const countryMatch = allCountry?.length > 0 ? allCountry.includes(item.country) : true;

          return importanceMatch && countryMatch;
        });

      setFilteredAlertsData(filteredResponse);
      // Handle the filtered response as needed
    }
  }, [selectedImportance, allCountry]);

  const countryAccount =
    igCompanySiteId && accounts[igCompanySiteId]
      ? getCountryCode(accounts[igCompanySiteId])[0][0]
      : 'US';
  const countryContinent =
    igCompanySiteId && accounts[igCompanySiteId]
      ? findContinentbyCountry(igCompanySiteId)
      : 'Americas';

  if (!continents[countryContinent]?.includes(countryAccount)) {
    continents[countryContinent]?.push(countryAccount);
  }

  const totalCountryLength = Object.values(continents).reduce(
    (sum, value) => sum + value.length,
    0,
  );

  const handleSelectAll = () => {
    if (allCountry.length === totalCountryLength) {
      setAllCountry([]);
    } else {
      // Flatten all countries into a single array
      const countries = Object.values(continents).flat();
      // Map country codes to their names using the provided map
      const updatedCountries = countries.map((country) => countryCodeToNameMap[country]);
      // Update the state with the transformed data
      setAllCountry(updatedCountries);
    }
  };

  const setBulkAlertHandler = () => {
    setShowBulkAlertSettingWarning(true);
  };

  const enableMacroSetBulkAlert =
    selectedImportance?.length > 0 && allCountry?.length > 0 && selectedDate;
  const enableEarningsSetBulkAlert = companyName?.length > 0 && selectedDate;

  return selectedAlertType === 'macroeconomicEvents' ? (
    <div className={notWebsite ? style.macroWrapperMobile : style.macroWrapper}>
      <div className={notWebsite ? style.filterSectionMobile : style.filterSelection}>
        <div
          className={`${notWebsite ? style.flexSectionMobile : style.gridSection} ${isAndroid ? style.flexSectionMobileAndroid : style.none}`}
        >
          <p
            className={`${notWebsite ? style.textMobile : style.gridText} ${isAndroid ? style.textMobileAndroid : style.none}`}
          >
            {notWebsite ? t('Importance') : t('Importance Rating')}
          </p>
          <ImportanceFilterBox
            selectedImportance={selectedImportance}
            extendedImportanceFilter={extendedImportanceFilter}
            updateExtendedImportanceFilter={setExtendedImportanceFilter}
            handleImportance={handleImportance}
            showBulkAlertsDrawer={showBulkAlertsDrawer}
            isAlert={true}
          />
        </div>
        <div
          className={`${notWebsite ? style.flexSectionMobile : style.gridSection} ${isAndroid ? style.flexSectionMobileAndroid : style.none}`}
        >
          <p
            className={`${notWebsite ? style.textMobile : style.gridText} ${notWebsite ? style.textMobileCountry : style.none} ${isAndroid ? style.textMobileAndroid : style.none}`}
          >
            {notWebsite ? (
              <>
                <span>
                  {t('Country')}
                  {(isIos || isIpad) && `(${allCountry.length}/${totalCountryLength})`}
                </span>
                {!isAndroid && (
                  <button className={style.selectAllBtn} onClick={handleSelectAll}>
                    {allCountry.length === totalCountryLength ? t('Unselect all') : t('Select all')}
                  </button>
                )}
              </>
            ) : (
              t('Country')
            )}
          </p>
          <AlertCountryFilter
            isAlert={true}
            allCountry={allCountry}
            setAllCountry={setAllCountry}
            totalCountryLength={totalCountryLength}
          />
        </div>
        <CustomDateInputWrapper
          notWebsite={notWebsite}
          selectedDate={selectedDate}
          formatDate={formatDate}
          setSelectedDate={setSelectedDate}
          isAndroid={isAndroid}
          isIos={isIos || isIpad}
          t={t}
        />
      </div>
      {!notWebsite && (
        <button
          className={`${styles.setBulkAlert} ${enableMacroSetBulkAlert ? styles.enabledSetBulkAlert : styles.disabledSetBulkAlert}`}
          onClick={enableMacroSetBulkAlert ? setBulkAlertHandler : undefined}
        >
          {t('Set bulk alerts')}
        </button>
      )}
    </div>
  ) : (
    <div className={styles.earningsContainer}>
      <div className={notWebsite ? styles.earningsSelectionBoxMobile : styles.earningsSelectionBox}>
        <div className={style.gridSection}>
          {!notWebsite && (
            <p className={style.gridText}>{t('Set alerts for specific companies')}</p>
          )}
          <div className={notWebsite ? styles.searchBarMobile : styles.none}>
            <BulkAlertsSearchCompany onCompanySelected={handleCompanySelected} />
          </div>
        </div>
        <CustomDateInputWrapper
          notWebsite={notWebsite}
          selectedDate={selectedDate}
          formatDate={formatDate}
          setSelectedDate={setSelectedDate}
          isAndroid={isAndroid}
          isIos={isIos || isIpad}
          t={t}
        />
      </div>
      {!notWebsite && (
        <div className={notWebsite ? style.setAlertWrapperMobile : style.none}>
          <button
            className={`${notWebsite ? style.setBulkAlertMobile : styles.setBulkAlert} ${enableEarningsSetBulkAlert ? styles.enabledSetBulkAlert : styles.disabledSetBulkAlert}`}
            onClick={setBulkAlertHandler}
          >
            {t('Set bulk alerts')}
          </button>
        </div>
      )}
    </div>
  );
}
