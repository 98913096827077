import { useState } from 'react';
import { useAlerts } from '../../../../hooks/useAlerts';
import BulkAlertEarningsMobileView from './BulkAlertEarningsMobileView';
import BulkAlertMacroMobileView from './BulkAlertMacroMobileView';
import style from './BulkAlertMobileData.module.css';
import { bulkAlertTabs } from '../../../../utils/alertUtils';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useTranslation } from 'react-i18next';

export default function BulkAlertMobileDataIndividual({ data, text }) {
  const [viewMoreStates, setViewMoreStates] = useState({});
  const { activeAlertTab, handleDeleteAllAlerts } = useAlerts();
  const { isAndroid } = useEconCalendarParams();
  const { t } = useTranslation();

  const toggleViewMore = (category) => {
    setViewMoreStates((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const isViewMore = viewMoreStates[text];

  return (
    data &&
    data?.length > 0 && (
      <div className={style.bulkWrapper}>
        <div className={isAndroid ? style.bulkContainerAndroid : style.bulkContainer}>
          <p className={isAndroid ? style.headTitleAndroid : style.headTitle}>
            {t(bulkAlertTabs[text])}
          </p>
          {data && data?.length > 1 && (
            <button
              className={isAndroid ? style.deleteAllBtnAndroid : style.deleteAllBtn}
              onClick={() => handleDeleteAllAlerts(text)}
            >
              {t('Delete all')}
            </button>
          )}
        </div>
        {activeAlertTab !== 'alertSettings' &&
          data &&
          data.length > 0 &&
          (text === 'macroeconomicEvents' ? (
            <BulkAlertMacroMobileView
              alertItems={isViewMore ? data : data.slice(0, 3)}
              text={text}
            />
          ) : (
            <BulkAlertEarningsMobileView
              alertItems={isViewMore ? data : data.slice(0, 3)}
              text={text}
            />
          ))}

        {!isViewMore && data?.length > 3 && (
          <button className={style.viewMoreBtn} onClick={() => toggleViewMore(text)}>
            {t('view-more')} ({data?.length - 3})
          </button>
        )}
        {isViewMore && (
          <button className={style.viewMoreBtn} onClick={() => toggleViewMore(text)}>
            {t('View less')}
          </button>
        )}
      </div>
    )
  );
}
