import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  RefObject,
  useCallback,
  useLayoutEffect,
} from 'react';
import { DividendsReport } from '../types/dividendsReport';
import { EarningsReport } from '../types/earningsReport';
import { IPO } from '../types/ipos';
import { MacroEvent } from '../types/macroEvent';
import { StockSplit } from '../types/stock-splits';
import { useEconCalendarParams } from './use-econ-calendar-params';
import { HistoricalData } from '../types/historicalData';

import {
  handleEconProcessing,
  filterDataOnDateFilters,
  filterDatesOnDateFilters,
  getDateForAnalytics,
  convertDateToUserTimezone,
  filterSeachData,
} from '../utils/econ-utils';
import {
  continents,
  getDateRangeAccountTime,
  getDateRangeLocal,
  importanceMapping,
  getCountryCode,
  decodeHtmlEntities,
  arraysHaveSameElements,
  getContinentForCountry,
} from '../utils/util';
import {
  getEconData,
  getEconSearchData,
  getHistoricalData,
  getLatestUpdates,
} from '../api/EconCalendarApi';
import { useSessionData } from './use-session-data';
import { accounts } from '../utils/accountRegions';
import { tabsTitles } from '../utils/tabsDataAccess-utils';

type EconCalContextType = {
  authString: string;
  econData: {};
  data: Record<string, DividendsReport[] | EarningsReport[] | IPO[] | MacroEvent[] | StockSplit[]>;
  rawData: MacroEvent[];
  activeTab: string;
  changeActiveTab: (id: string) => void;
  changeDateFilter: (dateFilter: string, value: string) => void;
  fetchHistoricalData: (id: string) => void;
  dates: string[];
  chartData: HistoricalData[];
  allCountry: string[];
  selectedContinents: string[];
  selectCountry: (id: string) => void;
  selectedImportance: string[];
  handleImportance: (id: string) => void;
  selectedChartSection: MacroEvent;
  isDataLoading: boolean;
  isChartLoading: boolean;
  isExpanded: boolean;
  handleSelectDeselect: (name: string, type: string, resp: string[]) => void;
  updateResultData: () => void;
  activeDateFilter: string[];
  updateExtendedImportanceFilter: () => void;
  updateExtendedCountryFilter: () => void;
  extendedImportanceFilter: boolean;
  extendedCountryFilter: boolean;
  updateContinentSelection: (name: string, type: string) => void;
  filteredDates: {};
  updateSearchText: (text: string) => void;
  isTabChanged: boolean;
  showDetail: boolean;
  toggleDetail: (data: {} | string) => void;
  eventDetailValue: {} | string;
  expandTabs: boolean;
  updateExpandTabs: (value: boolean) => void;
  showSearch: boolean;
  setShowSearch: (value: boolean) => void;
  updateShowSearch: () => void;
  showFilters: boolean;
  updateShowFilters: () => void;
  errorMessage: null | string;
  updatedData: object;
  showBanner: boolean;
  updateShowBanner: (value: boolean) => void;
  searchText: string;
  oldEconCalendarUrl: object;
  calendarWidth: number;
  updateCalendarWidth: (width: number) => void;
  inputRef: RefObject<HTMLInputElement>;
  expandBanner: boolean;
  updateExpandBanner: (value) => void;
  updateCalendarVisibility: (value) => void;
  showCalendar: boolean;
  handleResetToDefault: (val: string) => void;
  defaultFilterButtonDisabled: boolean;
  eventsDefaultContinentList: string[];
  eventsDefaultCountryList: string[];
  nonEventsDefaultContinentList: string[];
  nonEventsDefaultCountryList: string[];
  updateResetToDefaultState: (val: boolean) => void;
  fetchData: (value: string, filter: string) => void;
  isClearFilters: boolean;
  showInfo: boolean;
  updateShowInfo: () => void;
};

export const EconCalendarContext = createContext<EconCalContextType | null>(null);

export function useEconCalendar() {
  return useContext(EconCalendarContext);
}

const eventsDefaultCountryList = ['GB', 'US', 'AU', 'JP', 'EU', 'DE', 'CH', 'CN'];
const nonEventsDefaultCountryList = ['US'];

const eventsDefaultContinentList = [
  'Americas',
  'Asia and Pacific',
  'Euro Area',
  'Non-Eurozone Europe',
];
const nonEventsDefaultContinentList = ['Americas'];

export const EconCalendarProvider = ({ authString, oldEconCalendarUrl, children }) => {
  const { calendarApiUrl, timezoneOffset, igCompanySiteId } = useSessionData();
  const { isIos, isWebsite, specifySpecificTab, eventIdForDetails, handleEventIdForDetails } =
    useEconCalendarParams();

  // @ts-ignore
  const dataLayer = window.dataLayer;
  const [econData, setEconData] = useState({});
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState('');
  const [activeDateFilter, setActiveDateFilter] = useState(['', '']);
  const [dates, setDates] = useState([]);
  const [filteredDates, setFilteredDates] = useState({});
  const { locale, workspaceId } = useEconCalendarParams();
  const [chartData, setChartData] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [selectedChartSection, setSelectedChartSection] = useState();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImportance, setSelectedImportance] = useState([]);
  const [extendedImportanceFilter, setExtendedImportanceFilter] = useState(false);
  const [extendedCountryFilter, setExtendedCountryFilter] = useState(false);
  const [selectedContinents, setSelectedContinents] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [eventDetailValue, setEventDetailValue] = useState('');
  const [expandTabs, setExpandTabs] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [showBanner, setShowBanner] = useState(true);
  const inputRef = useRef(null);
  const [expandBanner, setExpandBanner] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarWidth, setCalendarWidth] = useState(1000);
  const [defaultFilterButtonDisabled, setDefaultFilterButtonDisabled] = useState(true);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const ref = useRef(true);
  const econCalendarDate =
    activeDateFilter[0] === 'custom date'
      ? getDateForAnalytics(new Date(activeDateFilter[1]))
      : activeDateFilter[0];

  const updateExpandTabs = (value = null) => {
    if (value !== null) setExpandTabs(value);
    else setExpandTabs((prev) => !prev);
  };

  const updateCalendarVisibility = (value) => {
    setShowCalendar(value);
  };

  const updateShowInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const updateExpandBanner = (value) => {
    if (expandBanner !== value) setExpandBanner(value);
  };

  const updateShowSearch = () => {
    if (!isIos) {
      if (showSearch) {
        setSearchText('');
      }
      setShowSearch((prev) => !prev);
      if (isWebsite) {
        setSearchText('');
      } else {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }
    } else {
      updateSearchText('');
      setIsClearFilters(true);
    }
  };

  const updateResetToDefaultState = (val: boolean) => {
    setDefaultFilterButtonDisabled(val);
  };

  const updateShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const updateShowBanner = (value: boolean) => {
    setShowBanner(value);
  };

  const updateCalendarWidth = (width) => {
    setCalendarWidth(width);
  };

  useLayoutEffect(() => {
    setIsDataLoading(true);
    if (eventIdForDetails) {
      // fetch data from the macro events and filter the data to show the details screen
      // should be for ios, iPad or android
      setActiveTab('events');
      setSelectedImportance(['low', 'high', 'medium']);
      return;
    }
    const localStorageData = JSON.parse(
      localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`),
    );

    if (localStorageData) {
      const {
        activeTab,
        importanceFilterList,
        continentFilterList,
        countryFilterList,
        selectedDayPicker,
      } = localStorageData;
      setActiveTab(specifySpecificTab ? specifySpecificTab : activeTab || 'events');
      setSelectedImportance(importanceFilterList || ['high', 'medium']);
      setActiveDateFilter(selectedDayPicker || ['today', '']);

      setAllCountry(
        (activeTab && countryFilterList?.[activeTab]) ||
          (!activeTab && countryFilterList && countryFilterList?.['events']) ||
          (activeTab === 'events'
            ? eventsDefaultCountryList
            : activeTab === undefined
              ? eventsDefaultCountryList
              : nonEventsDefaultCountryList),
      );
      setSelectedContinents(
        (activeTab && continentFilterList?.[activeTab]) ||
          (!activeTab && continentFilterList && continentFilterList?.['events']) ||
          (activeTab === 'events'
            ? eventsDefaultContinentList
            : activeTab === undefined
              ? eventsDefaultContinentList
              : nonEventsDefaultContinentList),
      );
    } else if (ref.current) {
      setActiveTab(specifySpecificTab ? specifySpecificTab : 'events');
      setSelectedImportance(['high', 'medium']);
      setActiveDateFilter(['today', '']);
      setAllCountry(eventsDefaultCountryList);
      setSelectedContinents(eventsDefaultContinentList);
    }
  }, [workspaceId]);

  const changeActiveTab = (id) => {
    setIsDataLoading(true);
    setData({});
    setEconData({});
    setDates([]);
    setChartData([]);
    setActiveTab(id);
    dataLayer.push({
      event: 'econ_calendar_interaction',
      interaction_type: 'navigation',
      interaction_value: 'tab',
      econ_calendar_tab: tabsTitles[id], // the tab user navigated to
      econ_calendar_date: econCalendarDate,
    });
    setIsExpanded(false);
    const storageFilter = JSON.parse(localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`));
    if (id) {
      if (storageFilter?.countryFilterList && id in storageFilter.countryFilterList) {
        setAllCountry(storageFilter.countryFilterList[id]);
        setSelectedContinents(storageFilter?.continentFilterList[id]);
      } else {
        if (id === 'events') {
          setAllCountry(eventsDefaultCountryList);
          setSelectedContinents(eventsDefaultContinentList);
        } else {
          setAllCountry(nonEventsDefaultCountryList);
          setSelectedContinents(nonEventsDefaultContinentList);
        }
      }
    }

    const updatedStorageFilter = {
      ...storageFilter,
      activeTab: id,
    };

    setTimeout(() => {
      localStorage.setItem(
        `${workspaceId}_ECON_CALENDAR_FILTERS`,
        JSON.stringify(updatedStorageFilter),
      );
    }, 0);
  };

  const changeDateFilter = async (dateFilter: string, value: string) => {
    const storageFilter = JSON.parse(localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`));
    if (dateFilter !== 'custom date') {
      setIsDataLoading(true);
      setIsTabChanged(true);
      setSearchText('');
      setData(econData[dateFilter]);
      setDates(filteredDates[dateFilter]);
      setChartData([]);
      setIsExpanded(false);
      const updatedStorageFilter = {
        ...storageFilter,
        selectedDayPicker: [dateFilter, ''],
      };

      setTimeout(() => {
        activeDateFilter[0] && setIsDataLoading(false);
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);
      setActiveDateFilter([dateFilter, '']);
    } else {
      if (!('today' in econData)) {
        await fetchData('', 'today');
      }
      // debugger
      setActiveDateFilter([dateFilter, value]);
      !searchText && (await fetchData(value, dateFilter));
      setChartData([]);
      const updatedStorageFilter = {
        ...storageFilter,
        selectedDayPicker: [dateFilter, value],
      };

      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);
      setIsExpanded(false);
    }
  };

  const toggleDetail = (data = '') => {
    if (data !== '') {
      setEventDetailValue(data);
      setShowDetail(true);
    } else if (data == '') {
      if (eventDetailValue !== '') setEventDetailValue('');
      if (showDetail !== false) setShowDetail(false);
    }
  };

  const updateExtendedImportanceFilter = () => {
    if (!extendedImportanceFilter && extendedCountryFilter) setExtendedCountryFilter(false);
    setExtendedImportanceFilter((prev) => !prev);
  };

  const updateExtendedCountryFilter = () => {
    if (!extendedCountryFilter && extendedImportanceFilter) setExtendedImportanceFilter(false);
    setExtendedCountryFilter((prev) => !prev);
  };

  const updateSearchText = (text: string) => {
    setSearchText(text);
    if (text)
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'search',
        interaction_value: 'event',
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_impact_level: selectedImportance, // the impact of the events; could be low, high, medium and combination of all of them
      });
  };

  const dateCheckLatestUpdates = (event, dateCheck = '', filter = '') => {
    const activeFilter = dateCheck ? filter : activeDateFilter[0];
    const eventDateExtracted = new Date(event.date?.split('T')[0]);
    const eventDateOnly = new Date(
      eventDateExtracted.getFullYear(),
      eventDateExtracted.getMonth(),
      eventDateExtracted.getDate(),
    );
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    if (activeFilter === 'today') {
      return eventDateOnly.getTime() === todayDate.getTime();
    } else if (activeFilter === 'tomorrow') {
      const tomorrowDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      return eventDateOnly.getTime() === tomorrowDate.getTime();
    } else if (activeFilter === 'yesterday') {
      const yesterdayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      return eventDateOnly.getTime() === yesterdayDate.getTime();
    } else if (activeFilter === 'next 7 days') {
      const next7Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 8);
      const tomorrowDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      return (
        eventDateOnly.getTime() >= tomorrowDate.getTime() &&
        eventDateOnly.getTime() < next7Days.getTime()
      );
    } else if (activeFilter === 'next 14 days') {
      const next14Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 15);
      const tomorrowDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      return (
        eventDateOnly.getTime() >= tomorrowDate.getTime() &&
        eventDateOnly.getTime() < next14Days.getTime()
      );
    } else if (activeFilter === 'custom date') {
      const customDate = dateCheck ? new Date(dateCheck) : new Date(activeDateFilter[1]);
      const customDateOnly = new Date(
        customDate.getFullYear(),
        customDate.getMonth(),
        customDate.getDate(),
      );
      return eventDateOnly.getTime() === customDateOnly.getTime();
    }
  };

  const updateData = async (updatedValues) => {
    // Create a copy of the data to avoid mutating state directly
    const newData = { ...data };
    let updated = false;
    // Loop over the updatedData object
    for (const key in updatedValues) {
      const updatedEntry = updatedValues[key];

      // Loop over the dates in the data object
      for (const date in newData) {
        const entries = newData[date];
        // Find the entry with the same id
        const entryIndex = entries.findIndex((entry) => entry.id === updatedEntry.id);
        if (entryIndex !== -1) {
          // Compare lastUpdate values
          if (new Date(updatedEntry.lastUpdate) > new Date(entries[entryIndex].lastUpdate)) {
            updated = true;
            if (updatedEntry.event === '')
              updatedEntry.event = entries[entryIndex].event
                ? decodeHtmlEntities(entries[entryIndex].event)
                : updatedEntry.event;
            if (!updatedEntry.description)
              updatedEntry.description = entries[entryIndex].description
                ? entries[entryIndex].description
                : updatedEntry.description;
            if (!updatedEntry.category) {
              updatedEntry.category = entries[entryIndex].category;
            }
            if (!updatedEntry.forecast) updatedEntry.forecast = entries[entryIndex].forecast;
            if (!updatedEntry.actual) updatedEntry.actual = entries[entryIndex].actual;
            if (!updatedEntry.previous) updatedEntry.previous = entries[entryIndex].previous;

            // Update the entry if the updatedData entry has a later lastUpdate
            newData[date][entryIndex] = { ...entries[entryIndex], ...updatedEntry };
          }
        }
      }
    }

    // Update the state with the new data
    if (newData && Object.values(newData).length && updated) {
      setData(newData);
      const updatedEconData = { ...econData };
      Object.entries(econData).forEach((keys) => {
        if (keys[0] === activeDateFilter[0]) {
          updatedEconData[keys[0]] = newData;
        }
      });

      setEconData(updatedEconData);
    }

    if (Object.keys(updatedData).length) setUpdatedData({});
  };

  // checks whether the last polled data is there, if it's there it compares the lastUpdate and updates the data in the local variable
  // the local variable is finally set as the new updated data
  const handlePolling = () => {
    if (data && Object.values(data).length === 0) return null;
    const timerId = setInterval(async () => {
      try {
        const resp = await getLatestUpdates(
          // cst,
          authString,
          calendarApiUrl,
        );

        const respData = await resp.json();
        let updated = false;
        if (respData.length > 0) {
          const updatedValues = {};
          respData.forEach((value, index) => {
            if (dateCheckLatestUpdates(value)) {
              if (value.id in updatedData) {
                if (new Date(value.lastUpdate) > new Date(updatedData[value.id].lastUpdate)) {
                  if (value.event === '')
                    value.event = decodeHtmlEntities(updatedData[value.id].event);
                  if (!value.description) value.description = updatedData[value.id].description;
                  updatedValues[value.id] = { ...updatedData[value.id], ...value };
                  updated = true;
                }
              } else {
                updatedValues[value.id] = value;
                updated = true;
              }
            }
          });

          if (updated) {
            setUpdatedData(updatedValues);
            setTimeout(() => {
              updateData(updatedValues);
            }, 1000);
          }
        } else {
          if (Object.keys(updatedData).length) setUpdatedData({});
        }
      } catch (error) {
        console.error(error, 'Err');
      }
    }, 5000);
    return timerId;
  };

  const validateNextSetOfData = async (respData, toDate, count) => {
    if (respData.length < 999) {
      return respData;
    }

    let finalResp = [...respData];
    const seen = new Set(finalResp.map((item) => item.id));

    while (finalResp.length >= 999 * count) {
      // get timestamp of last data received in the finalResp
      const lastData = finalResp[finalResp.length - 1];
      const lastDate = lastData.date || lastData.exDate;

      let maxLastDate = new Date(lastDate);
      finalResp.forEach((item) => {
        const itemDate = new Date(item.date || item.exDate);
        if (itemDate > maxLastDate) {
          maxLastDate = itemDate;
        }
      });

      maxLastDate.setSeconds(0, 0); // Reset seconds and milliseconds to zero
      const maxLastDateString = maxLastDate.toISOString();

      try {
        const resp = await getEconData(
          {
            activeTab,
            fromDate: maxLastDateString,
            toDate,
            locale: locale === 'ja' ? 'en' : locale,
          },
          authString,
          calendarApiUrl,
        );
        if (resp.status === 200) {
          const responseData = await resp.json();
          count++;
          if (responseData.length > 0) {
            // Remove duplicates
            responseData.forEach((item) => {
              if (!seen.has(item.id)) {
                // Assuming each item has a unique 'id'
                seen.add(item.id);
                finalResp.push(item);
              }
            });
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    return finalResp;
  };

  const combineLatestUpdates = async (respData, dateCheck = '', filter = '') => {
    const updatedResp = [...respData];
    const resp = await getLatestUpdates(
      // cst,
      authString,
      calendarApiUrl,
    );

    const latestUpdatesData = await resp.json();
    if (latestUpdatesData && latestUpdatesData.length > 0) {
      // Loop through latestUpdates to find objects with unique ids
      latestUpdatesData.forEach((update) => {
        if (dateCheckLatestUpdates(update, dateCheck, filter)) {
          // Check if the update id is already present in resp
          const index = updatedResp.findIndex((item) => item.id === update.id);

          // If the id doesn't exist in resp, push the update object into the resp array
          if (index !== -1) {
            const value = updatedResp[index];
            if (value.lastUpdate < update.lastUpdate) {
              if (update.actual) value.actual = update.actual;
              if (update.forecast) value.forecast = update.forecast;
              if (update.previous) value.previous = update.previous;
              if (update.description) value.description = update.description;
              if (update.ticker) value.ticker = update.ticker;
              if (update.event) value.event = decodeHtmlEntities(update.event);
              if (update.category) value.category = update.category;
              if (update.lastUpdate) value.lastUpdate = update.lastUpdate;

              updatedResp[index] = value;
            }
          }
        }
      });
      return updatedResp;
    } else {
      return respData;
    }
  };
  // value will be either '' or date and filter will be today/tomorrow etc
  const fetchData = async (value, filter: string) => {
    try {
      setIsDataLoading(true);
      const [fromDate, toDate] =
        timezoneOffset !== null
          ? getDateRangeAccountTime(activeTab, timezoneOffset, value)
          : getDateRangeLocal(activeTab, value);
      let count = 1;
      const resp = await getEconData(
        { activeTab, fromDate, toDate, locale: locale === 'ja' ? 'en' : locale },
        // cst,
        authString,
        calendarApiUrl,
      );
      if (resp.status === 200) {
        const responseData = await resp.json();

        if (eventIdForDetails) {
          const eventResp = responseData.filter((item) => item.id === eventIdForDetails);
          if (eventResp && eventResp.length > 0) {
            setEventDetailValue(eventResp[0]);
            setShowDetail(true);
          } else {
            // @ts-ignore
            handleEventIdForDetails?.postMessage(
              JSON.stringify({
                type: 'error',
                identifier: 'EVENT_DETAILS',
                data: 'No such event found!',
              }),
            );
          }
          return;
        }
        const respData = await validateNextSetOfData(responseData, toDate, count);

        activeTab === 'events' && setRawData(respData);
        const addLatestUpdates = activeTab === 'events';
        const finalData = addLatestUpdates && (await combineLatestUpdates(respData, value, filter));
        const { datesData, processedData } =
          addLatestUpdates && finalData
            ? handleEconProcessing(finalData)
            : handleEconProcessing(respData);
        const filteredDates = filterDatesOnDateFilters(
          datesData,
          value === '',
          timezoneOffset,
          activeTab,
        );
        const dateFilteredData = filterDataOnDateFilters(
          processedData,
          value === '',
          timezoneOffset,
          activeTab,
        );
        if (value === '') {
          setEconData(() => ({ ...dateFilteredData, 'custom date': '' }));
          setFilteredDates(() => ({ ...filteredDates, 'custom date': '' }));
        } else {
          setEconData((prev) => ({ ...prev, 'custom date': dateFilteredData['custom date'] }));
          setFilteredDates((prev) => ({ ...prev, 'custom date': filteredDates['custom date'] }));
        }

        if (dateFilteredData[filter] && Object.keys(dateFilteredData[filter]).length)
          updateResultData(dateFilteredData[filter], filteredDates[filter]);
        else {
          setData({});
          setDates([]);
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      setErrorMessage(`We currently can't access calendar data. Please try again later.`);
    } finally {
      setIsDataLoading(false);
    }
  };

  const checkFilters = () => {
    const countryFilterCheck = allCountry && allCountry.length > 0;
    const importanceFilterCheck =
      ['events', 'earnings-reports'].includes(activeTab) &&
      selectedImportance &&
      selectedImportance.length > 0 &&
      selectedImportance.length <= 3;
    const hasFilters = countryFilterCheck || importanceFilterCheck;
    return { hasFilters, countryFilterCheck, importanceFilterCheck };
  };

  const updateResultData = useCallback(
    async (data = {}, dates = []) => {
      try {
        const { hasFilters, countryFilterCheck, importanceFilterCheck } = checkFilters();
        if (Object.keys(data).length === 0) {
          data = econData[activeDateFilter[0]] || {};
          dates = filteredDates[activeDateFilter[0]];
        }

        if (
          !countryFilterCheck ||
          (!importanceFilterCheck && ['events', 'earnings-reports'].includes(activeTab))
        ) {
          setErrorMessage('No events to display. Change the date range or broaden your filters.');
          setData({});
          setDates([]);
        } else if (searchText) {
          let newData = {};
          setIsDataLoading(true);
          const resp = await getEconSearchData(
            { activeTab, searchText, locale },
            authString,
            calendarApiUrl,
          );
          const respData = await resp.json();
          setIsDataLoading(false);

          const { datesData, processedData } = handleEconProcessing(respData);
          const filteredDatesData = new Set(); // Use Set to ensure uniqueness
          datesData.forEach((dateString) => {
            const [_, relativeDate] = convertDateToUserTimezone(dateString, timezoneOffset);
            filteredDatesData.add(relativeDate); // Add each finalDate to the Set
          });

          // Convert the Set back to an array if needed
          const uniqueDatesArray = Array.from(filteredDatesData);

          const searchDataFilteredOnDates = filterSeachData(processedData, timezoneOffset);

          if (Object.keys(searchDataFilteredOnDates).length === 0) {
            setErrorMessage('Sorry, no results match your search. Please try again.');
          } else {
            newData = hasFilters
              ? filterData(searchDataFilteredOnDates, countryFilterCheck, importanceFilterCheck)
              : searchDataFilteredOnDates;

            if (Object.keys(newData).length === 0) {
              setErrorMessage(
                'No events to display. Change the date range or broaden your filters.',
              );
            }
          }
          setData(newData);
          setDates(uniqueDatesArray);
        } else if (hasFilters && data) {
          const newData = filterData(data, countryFilterCheck, importanceFilterCheck);
          if (!newData || Object.keys(newData).length === 0)
            setErrorMessage('No events to display. Change the date range or broaden your filters.');
          setData(newData);
          setDates(dates);
        } else {
          if (data && Object.keys(data).length === 0)
            setErrorMessage('No events to display. Change the date range or broaden your filters.');
          setData(data);
          setDates(dates);
        }
      } finally {
        setTimeout(() => setIsClearFilters(false), 0);
      }
    },
    [data, econData, dates, searchText, checkFilters],
  );

  const checkCountryFilter = (eventData, importanceFilter) => {
    let countryCode;
    if (activeTab === 'dividends-reports') {
      countryCode = eventData?.symbol?.split(':')[1];
    } else {
      let countryName = eventData.country;
      if (eventData.country === 'European Union') countryName = 'Euro Area';
      countryCode = getCountryCode(countryName);
      countryCode = countryCode && countryCode.length > 0 && countryCode[0][0];
    }
    if (countryCode && countryCode.length > 0 && allCountry?.includes(countryCode)) {
      if (importanceFilter) {
        return checkImportanceFilter(eventData);
      } else return true;
    }
    return false;
  };

  const checkImportanceFilter = (eventData) => {
    return selectedImportance?.includes(importanceMapping[eventData.importance]);
  };

  const filterData = (processedData, countryFilter: boolean, importanceFilter: boolean) => {
    const filtered = {};

    processedData &&
      Object.entries(processedData).forEach(([keyDate, eventDatas]) => {
        // @ts-ignore
        eventDatas.map((eventData) => {
          const filterFlag = checkCountryFilter(eventData, importanceFilter);
          if (filterFlag) {
            if (!filtered[keyDate]) {
              filtered[keyDate] = [eventData];
            } else {
              filtered[keyDate] = [...filtered[keyDate], eventData];
            }
          }
        });
      });
    return filtered;
  };

  const fetchHistoricalData = async (selectedData) => {
    try {
      //@ts-ignore
      if (selectedData.id === selectedChartSection?.id && isExpanded) {
        setIsExpanded(false);
        return;
      }

      setIsExpanded(true);
      setIsChartLoading(true);
      setSelectedChartSection(selectedData);
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'open',
        interaction_value: 'event',
        econ_calendar_event_name: selectedData.event, // the name of the event the user opened
        econ_calendar_event_id: selectedData.id, // the event ID the user opened if available
        econ_calendar_date: econCalendarDate,
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_event_impact_level: importanceMapping[selectedData.importance], // the impact of the event
      });

      const resp = await getHistoricalData(
        selectedData.ticker,
        // cst,
        authString,
        calendarApiUrl,
      );
      if (resp.status === 200) {
        const data = await resp.json();
        setChartData(data);
      } else {
        setChartData([]); // Ensure chartData is cleared if response is not successful
      }
    } catch (error) {
      console.error('Error fetching historical data:', error);
      setChartData([]); // Ensure chartData is cleared on error
    } finally {
      setIsChartLoading(false); // Ensure loading state is always reset
    }
  };

  const selectCountry = (code: string) => {
    setDefaultFilterButtonDisabled(false);
    if (allCountry?.includes(code)) {
      const resp = allCountry?.filter((item) => item !== code);
      const uniqueResp = Array.from(new Set(resp)); // Remove duplicates
      setAllCountry(uniqueResp);

      const relevantContinent = selectedContinents?.filter((value) => {
        if (continents[value]?.includes(code)) return value;
      });
      const countries = continents[relevantContinent[0]];

      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'country', // the country user selected/deselected
        econ_calendar_country: [code], // country in ISO code
        econ_calendar_action: 'deselected', // or 'deselected'
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_impact_level: selectedImportance, // the impact level user selected
      });
      const storageFilter = JSON.parse(
        localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`),
      );

      const updatedStorageFilter = {
        ...storageFilter,
        countryFilterList: { ...(storageFilter?.countryFilterList || {}), [activeTab]: uniqueResp },
      };

      if (!(countries && countries.some((val) => uniqueResp?.includes(val)))) {
        updateContinentSelection(relevantContinent[0], 'deselect');
        updatedStorageFilter.continentFilterList = {
          ...(updatedStorageFilter?.continentFilterList || {}),
          [activeTab]: selectedContinents?.filter((item) => item !== relevantContinent[0]),
        };
        setTimeout(() => {
          localStorage.setItem(
            `${workspaceId}_ECON_CALENDAR_FILTERS`,
            JSON.stringify(updatedStorageFilter),
          );
        }, 0);
      } else {
        setTimeout(() => {
          localStorage.setItem(
            `${workspaceId}_ECON_CALENDAR_FILTERS`,
            JSON.stringify(updatedStorageFilter),
          );
        }, 0);
      }
    } else {
      const relevantContinent = Object.keys(continents)?.filter((value) => {
        if (continents[value]?.includes(code)) return value;
      });

      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'country', // the country user selected/deselected
        econ_calendar_country: [code], // country in ISO code
        econ_calendar_action: 'selected', // or 'deselected'
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_impact_level: selectedImportance, // the impact level user selected
      });
      const uniqueResp = Array.from(new Set([...allCountry, code]));
      const storageFilter = JSON.parse(
        localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`),
      );
      const updatedStorageFilter = {
        ...storageFilter,
        countryFilterList: {
          ...(storageFilter?.countryFilterList || {}),
          [activeTab]: uniqueResp,
        },
      };

      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);

      const countries = continents[relevantContinent[0]];
      if (!countries.every((country) => allCountry?.includes(country))) {
        updateContinent(relevantContinent[0]);
        const uniqueContinents = Array.from(new Set([...selectedContinents, relevantContinent[0]]));

        updatedStorageFilter.continentFilterList = {
          ...(updatedStorageFilter?.continentFilterList || {}),
          [activeTab]: uniqueContinents,
        };
      } else {
        const updatedStorageFilter = {
          ...storageFilter,
          countryFilterList: {
            ...(storageFilter?.countryFilterList || {}),
            [activeTab]: uniqueResp,
          },
        };

        setTimeout(() => {
          localStorage.setItem(
            `${workspaceId}_ECON_CALENDAR_FILTERS`,
            JSON.stringify(updatedStorageFilter),
          );
        }, 0);
      }

      setAllCountry((prev) => [...prev, code]);
    }
  };

  const updateContinent = (name: string) => {
    let resp = [...selectedContinents, name];
    let uniqueResp = Array.from(new Set(resp));
    setSelectedContinents(uniqueResp);
  };

  const updateContinentSelection = (name: string, type: string) => {
    if (type === 'select') {
      let resp = new Set([...selectedContinents, name]);
      let updatedResp = Array.from(resp);
      setSelectedContinents(updatedResp);
      handleSelectDeselect(name, type, updatedResp);
    } else {
      let resp = selectedContinents;
      let filteredResp = resp?.filter((item) => item !== name);
      setSelectedContinents(filteredResp);
      handleSelectDeselect(name, type, filteredResp);
    }
  };

  // For Continent Select and De-Select
  const handleSelectDeselect = (name: string, type: string, filteredContinents: string[]) => {
    setDefaultFilterButtonDisabled(false);
    const storageFilter = JSON.parse(localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`));
    if (type === 'select') {
      let resp = [];
      for (const countryCode of continents[name]) {
        if (!allCountry?.includes(name)) {
          resp = [...resp, countryCode];
        }
      }

      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'country', // the country user selected/deselected
        econ_calendar_country: resp, // country in ISO code
        econ_calendar_action: 'selected', // or 'deselected'
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_impact_level: selectedImportance, // the impact level user selected
      });
      const uniqueCountries = Array.from(new Set([...allCountry, ...resp]));
      const updatedStorageFilter = {
        ...storageFilter,
        countryFilterList: {
          ...(storageFilter?.countryFilterList || {}),
          [activeTab]: uniqueCountries,
        },
        continentFilterList: {
          ...(storageFilter?.continentFilterList || {}),
          [activeTab]: filteredContinents,
        },
      };
      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);

      setAllCountry(uniqueCountries);
    } else {
      let resp = [...allCountry];
      let filteredResp = resp?.filter((item) => !continents[name]?.includes(item));
      let uniqueFilteredResp = Array.from(new Set(filteredResp));
      const itemsDeselected = resp?.filter((item) => continents[name]?.includes(item));
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'country', // the country user selected/deselected
        econ_calendar_country: itemsDeselected, // country in ISO code
        econ_calendar_action: 'deselected', // or 'deselected'
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_impact_level: selectedImportance, // the impact level user selected
      });
      const updatedStorageFilter = {
        ...storageFilter,
        countryFilterList: {
          ...(storageFilter?.countryFilterList || {}),
          [activeTab]: uniqueFilteredResp,
        },
        continentFilterList: {
          ...(storageFilter?.continentFilterList || {}),
          [activeTab]: filteredContinents,
        },
      };
      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);
      setAllCountry(uniqueFilteredResp);
    }
  };

  const handleImportance = (importance) => {
    const storageFilter = JSON.parse(localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`));
    if (selectedImportance?.includes(importance)) {
      const resp = selectedImportance?.filter((item) => item !== importance);
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'impact',
        econ_calendar_impact_level: importance, // the impact level user selected/deselected
        econ_calendar_action: 'deselected',
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_country: allCountry, // the country in ISO code format that was selected in filters
      });
      const updatedStorageFilter = {
        ...storageFilter,
        importanceFilterList: resp,
      };
      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);
      setSelectedImportance(resp);
    } else {
      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'filter',
        interaction_value: 'impact',
        econ_calendar_impact_level: importance, // the impact level user selected/deselected
        econ_calendar_action: 'selected',
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing
        econ_calendar_date: econCalendarDate,
        econ_calendar_country: allCountry, // the country in ISO code format that was selected in filters
      });
      const updatedStorageFilter = {
        ...storageFilter,
        importanceFilterList: [...selectedImportance, importance],
      };

      setTimeout(() => {
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);
      setSelectedImportance((prev) => [...prev, importance]);
    }
  };

  const clearFilters = () => {
    setIsClearFilters(true);
    const value = accounts[igCompanySiteId] && getCountryCode(accounts[igCompanySiteId]);
    const continentName = getContinentForCountry(igCompanySiteId);

    if (!continents[continentName]?.includes(value[0][0])) {
      continents[continentName]?.push(value[0][0]);
    }
    const countryList = Array.from(new Set(Object.values(continents).flat()));
    const continentList = Array.from(new Set(Object.keys(continents)));
    setSelectedImportance(['high', 'medium', 'low']);
    setSelectedContinents(continentList);
    setAllCountry(countryList);

    const storageFilter = JSON.parse(localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`));
    const updatedStorageFilter = {
      ...storageFilter,
      importanceFilterList: ['high', 'medium', 'low'],
      countryFilterList: {
        ...(storageFilter?.countryFilterList || {}),
        [activeTab]: countryList,
      },
      continentFilterList: {
        ...(storageFilter?.continentFilterList || {}),
        [activeTab]: continentList,
      },
    };

    setTimeout(() => {
      localStorage.setItem(
        `${workspaceId}_ECON_CALENDAR_FILTERS`,
        JSON.stringify(updatedStorageFilter),
      );
    }, 0);
    updateResultData();
  };

  const handleResetToDefault = (check = '') => {
    if (check) {
      clearFilters();
    } else {
      if (activeTab === 'events') {
        if (!arraysHaveSameElements(allCountry, eventsDefaultCountryList)) {
          setAllCountry(eventsDefaultCountryList);
          setSelectedContinents(eventsDefaultContinentList);
        }
      } else {
        if (!arraysHaveSameElements(allCountry, nonEventsDefaultCountryList)) {
          setAllCountry(nonEventsDefaultCountryList);
          setSelectedContinents(nonEventsDefaultContinentList);
        }
      }

      const storageFilter = JSON.parse(
        localStorage.getItem(`${workspaceId}_ECON_CALENDAR_FILTERS`),
      );

      const updatedStorageFilter = {
        ...storageFilter,
      };

      if ('continentFilterList' in updatedStorageFilter)
        delete updatedStorageFilter['continentFilterList'][activeTab];

      if ('countryFilterList' in updatedStorageFilter)
        delete updatedStorageFilter['countryFilterList'][activeTab];

      setTimeout(() => {
        setDefaultFilterButtonDisabled(true);
        localStorage.setItem(
          `${workspaceId}_ECON_CALENDAR_FILTERS`,
          JSON.stringify(updatedStorageFilter),
        );
      }, 0);

      dataLayer.push({
        event: 'econ_calendar_interaction',
        interaction_type: 'reset_to_default',
        interaction_value: 'countries',
        econ_calendar_tab: tabsTitles[activeTab], // the tab the user is currently viewing,
        econ_calendar_date: econCalendarDate, // the date the user is currently viewing: yesterday, today, tomorrow, next 7 days, next 14 days or custom date,
        econ_calendar_country: allCountry, // previous state of countries filter in ISO code as an array
      });
    }
  };

  useEffect(() => {
    if (activeTab) {
      const [dateFilter, value] = activeDateFilter;

      if (dateFilter === 'custom date') {
        changeDateFilter(dateFilter, value);
      } else {
        setActiveDateFilter([dateFilter, '']);
        fetchData('', dateFilter);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    let timerId = null;
    if (activeTab === 'events') {
      timerId = handlePolling();
    }

    return () => timerId && clearInterval(timerId);
  }, [activeTab, data, activeDateFilter]);

  useEffect(() => {
    if (isTabChanged) {
      updateResultData();
      setIsTabChanged(false);
    }
  }, [isTabChanged, activeDateFilter]);

  useEffect(() => {
    if (!ref.current) {
      updateResultData();
    }
  }, [searchText, allCountry, selectedImportance]);

  useEffect(() => {
    ref.current = false;
  }, []);

  useEffect(() => {
    const value = accounts[igCompanySiteId] && getCountryCode(accounts[igCompanySiteId]);

    if (value) {
      !eventsDefaultCountryList?.includes(value[0][0])
        ? eventsDefaultCountryList?.push(value[0][0])
        : eventsDefaultCountryList;

      !nonEventsDefaultCountryList?.includes(value[0][0])
        ? nonEventsDefaultCountryList?.push(value[0][0])
        : nonEventsDefaultCountryList;

      const foundRegion = Object.entries(continents).find(([key, countries]) =>
        countries?.includes(value[0][0]),
      );

      if (foundRegion) {
        const [region] = foundRegion;
        !eventsDefaultContinentList?.includes(region)
          ? eventsDefaultContinentList?.push(region)
          : eventsDefaultContinentList;

        !nonEventsDefaultContinentList?.includes(region)
          ? nonEventsDefaultContinentList?.push(region)
          : nonEventsDefaultContinentList;
      }
    }
  }, [igCompanySiteId]);

  return (
    <EconCalendarContext.Provider
      value={{
        updatedData,
        errorMessage,
        econData,
        data,
        activeTab,
        changeActiveTab,
        changeDateFilter,
        dates,
        fetchHistoricalData,
        chartData,
        allCountry,
        selectedContinents,
        selectCountry,
        selectedChartSection,
        isDataLoading,
        isExpanded,
        isChartLoading,
        handleSelectDeselect,
        selectedImportance,
        handleImportance,
        updateResultData,
        activeDateFilter,
        updateExtendedImportanceFilter,
        updateExtendedCountryFilter,
        extendedCountryFilter,
        extendedImportanceFilter,
        updateContinentSelection,
        filteredDates,
        updateSearchText,
        isTabChanged,
        showDetail,
        toggleDetail,
        eventDetailValue,
        expandTabs,
        updateExpandTabs,
        showSearch,
        updateShowSearch,
        showFilters,
        updateShowFilters,
        showBanner,
        updateShowBanner,
        searchText,
        authString,
        oldEconCalendarUrl,
        calendarWidth,
        updateCalendarWidth,
        inputRef,
        expandBanner,
        updateExpandBanner,
        showCalendar,
        updateCalendarVisibility,
        handleResetToDefault,
        defaultFilterButtonDisabled,
        eventsDefaultContinentList,
        eventsDefaultCountryList,
        nonEventsDefaultContinentList,
        nonEventsDefaultCountryList,
        updateResetToDefaultState,
        fetchData,
        isClearFilters,
        showInfo,
        updateShowInfo,
        rawData,
        setShowSearch,
      }}
    >
      {children}
    </EconCalendarContext.Provider>
  );
};
