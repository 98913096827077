import style from '../../styles/EconCalendarTabs.module.css';
import { eventNameMappingForMobile } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import FiltersArrowDown from '../../assets/svgs/FiltersArrowDown';
import { tabsTitles } from '../../utils/tabsDataAccess-utils';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import SelectedTabTick from '../../assets/svgs/SelectedTabTick';
import CancelSearchIos from '../../assets/svgs/CancelSearchIos';

interface TabsListProps {
  isMobile: boolean;
  expandTabs: boolean | null;
  handleListClass: string;
  sectionRef: React.RefObject<HTMLUListElement>;
  focusedButton: string | null;
  setFocusedButton: React.Dispatch<React.SetStateAction<string | null>>;
  activeTab: string;
  changeActiveTab: (key: string) => void;
  updateExpandTabs: (value: boolean | null) => void;
  showDetail: boolean;
  isDataLoading: boolean;
  updateCalendarVisibility: (value) => void;
}

const TabsList: React.FC<TabsListProps> = ({
  isMobile,
  expandTabs,
  handleListClass,
  sectionRef,
  focusedButton,
  setFocusedButton,
  activeTab,
  changeActiveTab,
  updateExpandTabs,
  showDetail,
  isDataLoading,
  updateCalendarVisibility,
}) => {
  const { t } = useTranslation();
  const { isIos } = useEconCalendarParams();
  const updateTabs = (key: string, buttonElement: HTMLButtonElement) => {
    if (key !== activeTab) {
      changeActiveTab(key);
    }
    updateExpandTabs(null);
    setFocusedButton(key);
    buttonElement.blur();
    updateCalendarVisibility(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, key: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      updateTabs(key, event.currentTarget);
      event.preventDefault();
    }
  };

  return (
    <>
      {isMobile && expandTabs && isIos && (
        <div className={style.calendarTypeSection}>
          <div className={style.calendarType}>
            <span className={style.calendarTypeText}> {t('calendar-type')} </span>
          </div>
          <button className={style.calendarTypeCrossIcon} onClick={() => updateExpandTabs(null)}>
            <CancelSearchIos />
          </button>
        </div>
      )}
      <ul
        ref={sectionRef}
        className={`${handleListClass} ${isMobile && isIos ? style.iosTabs : style.none}`}
      >
        {!isMobile || expandTabs ? (
          Object.entries(tabsTitles).map(([key, title]) => {
            const isActive = activeTab === key;
            return (
              <li
                className={`${
                  !isMobile ? (isActive ? style.active : style.inactive) : style.none
                } ${isDataLoading ? style.noCursor : style.cursor}`}
                key={key}
              >
                <button
                  disabled={isDataLoading}
                  onMouseDown={() => setFocusedButton(null)}
                  onClick={(e) => updateTabs(key, e.currentTarget)}
                  onKeyDown={(event) => handleKeyDown(event, key)}
                  className={`${focusedButton === key ? style.focusedButton : 'none'} ${
                    isMobile
                      ? isIos
                        ? isActive
                          ? style.iosActive
                          : style.none
                        : style.androidBtn
                      : style.none
                  }`}
                  aria-pressed={isActive}
                  aria-label={t(title)}
                  data-testid={title.split(' ').join('-')}
                >
                  <span className={isMobile ? 'calendar-regular' : 'none'}> {t(title)} </span>
                  {isMobile && isIos && isActive && <SelectedTabTick />}
                </button>
              </li>
            );
          })
        ) : (
          <li className={style.mobileActiveTab}>
            <div className={style.activeTabSection}>
              {showDetail && !isIos ? (
                <span className={`${style.details} calendar-medium`}> {t('Details')} </span>
              ) : (
                <button onClick={() => !isIos && updateExpandTabs(null)} data-testid="update-tabs">
                  <span
                    className={`${style.tabName} ${isMobile ? 'calendar-medium' : 'none'} ${
                      isMobile
                        ? isIos
                          ? style.mobileTabName
                          : style.mobileTabNameAndroid
                        : style.none
                    }`}
                  >
                    {t(eventNameMappingForMobile[activeTab])}
                  </span>
                  {!isIos && <FiltersArrowDown />}
                </button>
              )}
            </div>
          </li>
        )}
      </ul>
    </>
  );
};

export default TabsList;
