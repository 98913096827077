import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function DeleteIconAlerts({ width = '16', height = '16' }) {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#8292A6' : '#B5BDBE';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="delete">
        <path
          id="Vector"
          d="M10.6673 6V12.6667H5.33398V6H10.6673ZM9.66732 2H6.33398L5.66732 2.66667H3.33398V4H12.6673V2.66667H10.334L9.66732 2ZM12.0007 4.66667H4.00065V12.6667C4.00065 13.4 4.60065 14 5.33398 14H10.6673C11.4007 14 12.0007 13.4 12.0007 12.6667V4.66667Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
