import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../../styles/TableRows.scss';
import styles from './CustomDateInput.module.css'; // Import the CSS module
import CalendarIconAlerts from '../../../../assets/svgs/CalendarIconAlerts';
import CalendarIconDateSelection from '../../../../assets/svgs/CalendarIconDateSelection';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import ExpandFiltersIcon from '../../../IconComponents/ExpandFiltersIcon';

const CustomDateInput = ({
  selectedDate,
  setSelectedDate,
  notWebsite,
  formatDate,
  isCalendarOpen,
  updateCalendarVisibility,
  dropdownRef,
  t,
}) => {
  const { isIos, currentTheme, isAndroid, fullLocale } = useEconCalendarParams();
  const calendarBackgroundStyle = currentTheme === 'dark' ? styles.darkTheme : styles.whiteTheme;

  return (
    <div className={notWebsite ? styles.containerMobile : styles.container} ref={dropdownRef}>
      {/* Custom Input */}
      <div className={notWebsite ? styles.dateInputMobile : styles.dateInput}>
        <div className={styles.iconContainer}>
          <span className={isAndroid ? styles.calendarIconAndroid : styles.calendarIcon}>
            {notWebsite ? (
              <CalendarIconDateSelection />
            ) : (
              <CalendarIconAlerts width={16} height={16} />
            )}
          </span>
          {!notWebsite && !selectedDate && (
            <span className={styles.datePlaceholder}> dd/mm/yyyy </span>
          )}
          {!notWebsite && selectedDate && (
            <span className={styles.dateText}>{formatDate(selectedDate, true)}</span>
          )}
        </div>
        {!notWebsite && (
          <span className={styles.arrowIcon}>
            <ExpandFiltersIcon />
          </span>
        )}
      </div>

      {isCalendarOpen && notWebsite && (
        <div
          className={styles.modal}
          onClick={() => updateCalendarVisibility(false)}
          role="button"
        />
      )}

      {/* Calendar */}
      {isCalendarOpen && (
        <div
          className={
            notWebsite
              ? isIos
                ? styles.calendarContainerMobile
                : styles.calendarContainerMobileAndroid
              : styles.calendarContainer
          }
        >
          <div
            className={
              notWebsite
                ? isIos
                  ? styles.calendarWrapperMobile
                  : styles.calendarWrapperMobileAndroid
                : styles.none
            }
          >
            <Calendar
              onClickDay={(date, event) => {
                event.stopPropagation(); // Prevents event bubbling
                setSelectedDate(date);
                updateCalendarVisibility(false);
              }}
              value={selectedDate}
              locale={fullLocale}
              minDate={new Date()}
              maxDate={(() => {
                const maxDate = new Date();
                maxDate.setMonth(maxDate.getMonth() + 3); // Set maxDate to 3 months from today
                return maxDate;
              })()}
              className={`${isIos ? styles.iosCalendar : isAndroid ? styles.androidCalendar : styles.none}  ${calendarBackgroundStyle} ${styles.mobileTheme} calendar-regular`}
            />
          </div>
          {isAndroid && (
            <div className={styles.buttonWrapper}>
              <button onClick={() => updateCalendarVisibility(false)}> {t('Cancel')} </button>
              <button onClick={() => updateCalendarVisibility(false)}> {t('Done')} </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDateInput;
