import { useEffect, useState, useRef } from 'react';
import styles from '../styles/Alerts/BulkAlertsSearch.module.css';
import { useEconCalendarParams } from './use-econ-calendar-params';
import SearchIconIos from '../assets/svgs/searchIconIos';
import CrossIcon from '../assets/svgs/CrossIcon';
import { useTranslation } from 'react-i18next';
import { getEconSearchData } from '../api/EconCalendarApi';
import { useSessionData } from './use-session-data';
import { getEnvURLs } from '../utils/env-utils';

const useBulkAlertsSearch = (onCompanySelected) => {
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isIos, isAndroid, isIpad, locale } = useEconCalendarParams();
  const { environmentId, calendarApiUrl } = useSessionData();
  const envURLs = getEnvURLs(environmentId);
  const authString = btoa(`${envURLs.tastyUserName}:${envURLs.tastyUserPassword}`);
  const notWebsite = isIos || isAndroid || isIpad;
  const { t } = useTranslation();
  const [earningsSearchData, setEarningsSearchData] = useState([]);

  // Ref to track if the update was from a suggestion selection
  const isSelectingSuggestion = useRef(false);

  useEffect(() => {
    setCompanyName(debouncedInputValue.trim());
  }, [debouncedInputValue]);

  const fetchEarningsEvents = async () => {
    try {
      const resp =
        debouncedInputValue &&
        (await getEconSearchData(
          { activeTab: 'earnings-reports', searchText: companyName, locale },
          authString,
          calendarApiUrl,
        ));

      if (resp.status === 200) {
        const responseData = await resp.json();
        setEarningsSearchData(responseData);
      }
    } catch (error) {
      console.log(error, 'Err');
    }
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(companyName);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [companyName]);

  // Update filtered suggestions whenever the input changes
  useEffect(() => {
    if (companyName && !isSelectingSuggestion.current) {
      const filtered = earningsSearchData.filter((item) =>
        item?.name?.toLowerCase()?.includes(companyName?.toLowerCase()),
      );
      setFilteredSuggestions(filtered);
      setShowDropdown(true);
    } else {
      setFilteredSuggestions([]);
      setShowDropdown(false);
    }

    // Reset the ref after each useEffect run
    isSelectingSuggestion.current = false;
  }, [earningsSearchData]);

  useEffect(() => {
    fetchEarningsEvents();
  }, [debouncedInputValue]);

  const handleSelectSuggestion = (suggestion) => {
    setCompanyName(suggestion.name);
    setShowDropdown(false); // Hide dropdown after selection
    isSelectingSuggestion.current = true; // Indicate selection
    onCompanySelected(suggestion.name);
  };

  return (
    <div
      className={
        notWebsite
          ? isAndroid
            ? styles.searchContainerMobileAndroid
            : styles.searchContainerMobile
          : styles.searchContainer
      }
    >
      {(!isAndroid || (isAndroid && !companyName.length)) && (
        <span
          className={
            isAndroid
              ? styles.searchIconAndroid
              : isIos || isIpad
                ? styles.searchIcon
                : styles.webSearchIcon
          }
        >
          <SearchIconIos />
        </span>
      )}
      <input
        type="text"
        placeholder={t('Search for a company')}
        className={`${notWebsite ? (isIos ? styles.searchCompanyMobile : styles.searchCompanyMobileAndroid) : styles.searchCompany} ${companyName.length === 0 ? styles.noCrossIcon : styles.none}`}
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        data-testid="search_input_earnings"
        aria-label="Search for a company"
      />
      {isAndroid && companyName.length > 0 && (
        <span className={styles.searchCompanyTopSection}> {t('Search for a company')} </span>
      )}
      {companyName.length > 0 && (
        <button
          onClick={() => setCompanyName('')}
          className={
            isAndroid
              ? styles.clearBtnAndroid
              : isIos || isIpad
                ? styles.clearBtn
                : styles.clearBtnWeb
          }
        >
          {!notWebsite ? <CrossIcon width="34" height="34" /> : <CrossIcon />}
        </button>
      )}
      {showDropdown && filteredSuggestions.length > 0 && (
        <ul className={`${styles.dropdown} ${!notWebsite ? styles.dropdownWeb : styles.none}`}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSelectSuggestion(suggestion)}
              className={`${styles.dropdownItem} ${notWebsite ? styles.none : styles.dropdownItemWeb}`}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default useBulkAlertsSearch;
