import { useEffect, useRef, useState } from 'react';
import { alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';
import AlertSettingsTableData from './AlertSettingsTableData';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import AlertSettingOptions from './AlertSettingOptions';
import { useAlerts } from '../../../../hooks/useAlerts';
import NoAlertsSetMobile from '../NoAlertsSetMobile';
import CrossIconMobile from '../../../IconComponents/CrossIconMobile';
import { useTranslation } from 'react-i18next';

export default function AlertSettings() {
  const {
    tabAlertData: alertsData,
    isAlertsLoading,
    showDefaultSettingsMobile,
    handleDeleteAllAlerts,
    updateShowDefaultSettingsMobile,
    handlePostAlertSettings,
    selectedAlertType,
  } = useAlerts();
  const [showDeleteAllConfirmationPopUp, setShowDeleteAllConfirmationPopUp] = useState(false);
  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const { isIos, isAndroid, currentTheme } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;
  const settingsKeys = Object.keys(alertSettingsConfigMobile);
  const deleteAllRef = useRef(null);
  const { t } = useTranslation();

  const updateShowInfo = (value) => {
    if (showInfoPopUp !== value) setShowInfoPopUp(value);
  };

  const handleDone = async () => {
    try {
      await handlePostAlertSettings();
    } catch (error) {
      console.log(error, 'Err');
    } finally {
      updateShowDefaultSettingsMobile(false);
    }
  };

  useEffect(() => {
    if (showDeleteAllConfirmationPopUp) {
      document.addEventListener('mousedown', handleClickedOutside);
    } else {
      document.removeEventListener('mousedown', handleClickedOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickedOutside);
    };
  }, [showDeleteAllConfirmationPopUp]);

  const handleClickedOutside = (event) => {
    if (
      deleteAllRef.current &&
      !deleteAllRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      setShowDeleteAllConfirmationPopUp(false);
    }
  };

  return (
    <div
      className={`${style.alertSettingsContainer} ${currentTheme === 'dark' ? style.darkTheme : style.lightTheme}`}
    >
      {notWebsite ? (
        <div className={style.defaultTextWrapper}>
          <button
            className={`${style.defaultTextButton} ${isAndroid ? style.androidDefaultBtn : style.iosDefaultBtn} ${alertsData && alertsData.length > 0 ? style.notDisabled : style.disabled}`}
            onClick={() => updateShowDefaultSettingsMobile(true)}
            disabled={!(alertsData && alertsData.length > 0)}
          >
            {t('Default Settings')}
          </button>
        </div>
      ) : (
        <p className={style.defaultText}>{t('Default Settings')}</p>
      )}
      {notWebsite && showDefaultSettingsMobile && <div className={style.modal}></div>}
      {((notWebsite && showDefaultSettingsMobile) || !notWebsite) && (
        <div className={notWebsite ? style.alertSettingsMobile : style.alertSettings}>
          {notWebsite && showDefaultSettingsMobile && (
            <div
              className={`${style.defaultSettingsMobileHeaderWrapper} ${isAndroid ? style.mobileHeaderWrapperAndroid : style.none}`}
            >
              <div className={style.defaultSettingsMobileHeader}>
                {!isAndroid && <button>{t('Reset')}</button>}
                <span>{t('Default Settings')}</span>
              </div>
              <button onClick={() => updateShowDefaultSettingsMobile(false)}>
                <CrossIconMobile />
              </button>
            </div>
          )}
          <AlertSettingOptions settingsKeys={settingsKeys} updateShowInfo={updateShowInfo} />
        </div>
      )}
      {notWebsite && showDefaultSettingsMobile && (
        <div className={style.defaultSettingDoneBtnWrapper}>
          <button onClick={() => handleDone()}>{t('Done')}</button>
        </div>
      )}
      {notWebsite && showInfoPopUp && (
        <>
          <div className={style.infoModal}></div>
          <div className={isAndroid ? style.showInfoWrapperAndroid : style.showInfoWrapper}>
            <div className={isAndroid ? style.showInfoBodyAndroid : style.showInfoBody}>
              <span> {t('Alert me')} </span>
              <span>
                {t(
                  'Pre-event warning applicable to macro-economic events only, as earnings are not announced at an exact pre-arranged time (only pre-open, after-close etc.)',
                )}
              </span>
            </div>
            <button
              className={isAndroid ? style.showInfoSectionBtnAndroid : style.showInfoSectionBtn}
              onClick={() => updateShowInfo(false)}
            >
              {t('Ok')}
            </button>
          </div>
        </>
      )}
      {notWebsite && showDeleteAllConfirmationPopUp && (
        <>
          <div className={style.modal}></div>
          <div className={style.deleteAllMobileWrapper} ref={deleteAllRef}>
            <div
              className={`${style.deleteAllMobileHeader} ${isAndroid ? style.deleteAllAndroidHeader : style.none}`}
            >
              <span>{t('Delete all alerts')}</span>
              <button onClick={() => setShowDeleteAllConfirmationPopUp(false)}>
                <CrossIconMobile />
              </button>
            </div>
            <div
              className={isAndroid ? style.deleteAllMobileBodyAndroid : style.deleteAllMobileBody}
            >
              <p className={isAndroid ? style.androidParagraph : style.iosParagraph}>
                {t(
                  'This will delete all your alerts. This cannot be undone. Are you sure you want to proceed?',
                )}
              </p>
            </div>
            <div
              className={`${style.deleteAllMobileBtns} ${isAndroid ? style.deleteAllAndroid : style.none}`}
            >
              <button
                onClick={async () => {
                  await handleDeleteAllAlerts(selectedAlertType);
                  setShowDeleteAllConfirmationPopUp(false);
                }}
              >
                {t('Delete all')}
              </button>
              <button
                className={isAndroid ? style.cancelBtnAndroid : style.none}
                onClick={() => setShowDeleteAllConfirmationPopUp(false)}
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </>
      )}
      <div>
        {!isAlertsLoading && (
          <div
            className={notWebsite ? style.alertDetailContainerMobile : style.alertDetailContainer}
          >
            {!notWebsite ? (
              <p className={style.alertDetailText}>
                {t('You have alerts set for')} {alertsData?.length || 0}{' '}
                {alertsData?.length > 1 ? t('economic events') : t('economic event')}
              </p>
            ) : (
              alertsData &&
              alertsData.length > 0 && (
                <div className={style.alertDetailWrapperMobile}>
                  <span className={isAndroid ? style.androidEventAlerts : style.iosEventAlerts}>
                    {t('Event Alerts')}
                  </span>
                  <button
                    className={isAndroid ? style.androidDeleteBtn : style.none}
                    onClick={() => setShowDeleteAllConfirmationPopUp(true)}
                  >
                    {t('Delete all')}
                  </button>
                </div>
              )
            )}
            {alertsData && alertsData.length > 0 ? (
              <AlertSettingsTableData data={alertsData} />
            ) : notWebsite ? (
              <NoAlertsSetMobile config="alerts" />
            ) : (
              <div className={style.noEventsDiv}>
                <p className={style.noEventsToDisplay}>{t('No events to display')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
