import { useEffect, useRef, useState } from 'react';
import styles from './AlertCountryFilter.module.css';
import { useEconCalendar } from '../../../../hooks/useEconCalendar';
import useScreenDimensions from '../../../../hooks/useScreenDimension';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { continents, searchCountryCode } from '../../../../utils/util';
import { countryCodeToNameMap } from '../../../../utils/countryCodeNameMapping';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { supportedCountryCodes } from '../../../../utils/supportedFlagIconList';
import PlaceholderFlag from '../../../../assets/svgs/PlaceholderFlag';
import CountryList from './CountryList';
import { useTranslation } from 'react-i18next';
import ExpandFiltersIcon from '../../../IconComponents/ExpandFiltersIcon';

export default function AlertCountryFilter({
  isAlert,
  allCountry,
  setAllCountry,
  totalCountryLength,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { isDataLoading } = useEconCalendar();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = useState(false);

  const isMobile = useScreenDimensions();
  const { isIos, isAndroid, currentTheme } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;
  const { t } = useTranslation();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const countryList = Object.values(continents).flat();

  const flagCode = (countryName) => {
    return searchCountryCode(countryName) as FlagIconCode;
  };

  const handleCheckboxChange = (country) => {
    setAllCountry((prevSelected) =>
      prevSelected.includes(country)
        ? prevSelected.filter((c) => c !== country)
        : [...prevSelected, country],
    );
  };

  // Filter the country list based on the search term
  const filteredCountryList = searchTerm
    ? countryList.filter((country) =>
        countryCodeToNameMap[country]?.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : countryList;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleExpand = () => {
    if (!expand) {
      setExpand(true);
    }
  };

  return (
    <div
      className={notWebsite ? styles.countryWrapperMobile : styles.countryWrapper}
      ref={dropdownRef}
    >
      {!notWebsite && (
        <button
          className={`${styles.alignment} ${
            isDataLoading ? styles.noCursor : styles.cursor
          } countryFilter ${isAlert && styles.alertFilterWrapper}`}
          onClick={toggleDropdown}
          data-testid="importance_filter_expand"
          disabled={isDataLoading}
        >
          <div
            className={`${styles.importance} ${
              isMobile && isIos ? styles.iosFilterSection : styles.none
            }`}
          >
            <div className={styles.countryText}> {t('Select country')} </div>
            <div className={styles.flagBox}>
              {allCountry &&
                allCountry.length > 0 &&
                allCountry.slice(0, 3).map((country, index) => {
                  return (
                    <div className={styles.flag} key={`${country}_${index}`}>
                      <span className={styles.flag}>
                        {supportedCountryCodes?.includes(flagCode(country)) ? (
                          // @ts-ignore
                          <FlagIcon code={flagCode(country)} loading="lazy" size={12} />
                        ) : (
                          <PlaceholderFlag />
                        )}
                      </span>
                    </div>
                  );
                })}
              {allCountry.length > 3 && (
                <span className={styles.remainingCount}>+{allCountry.length - 3}</span>
              )}
            </div>
          </div>
          <span className={styles.arrow}>
            <ExpandFiltersIcon />
          </span>
        </button>
      )}
      {isOpen && !notWebsite && (
        <div className={styles.dropdownMenu}>
          <div className={styles.spacing}>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                placeholder={t('Search for a country')}
                className={styles.searchBox}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <svg
                className={`${styles.searchIcon} ${currentTheme === 'dark' ? styles.darkIcon : styles.lightIcon}`}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Search">
                  <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.4096 2.73193C8.5656 1.05471 6.84731 -0.00258592 4.9697 4.7501e-06C2.81172 -0.000929137 0.900012 1.39158 0.239064 3.44585C-0.421884 5.50012 0.319155 7.74613 2.07281 9.00374C3.82646 10.2613 6.19149 10.2428 7.92522 8.95786L10.1949 11.6402C10.4453 11.9336 10.8391 12.0617 11.2142 11.9717C11.5892 11.8817 11.882 11.5888 11.972 11.2138C12.062 10.8387 11.9339 10.4449 11.6406 10.1945L8.95782 7.92539C10.0787 6.4191 10.2536 4.40916 9.4096 2.73193ZM8.89124 4.96935C8.89124 7.1361 7.13474 8.8926 4.96799 8.8926C2.80125 8.8926 1.04475 7.13609 1.04474 4.96935C1.04474 2.80259 2.80124 1.04609 4.96799 1.04609C7.13474 1.04609 8.89124 2.80259 8.89124 4.96935Z"
                  />
                </g>
              </svg>
            </div>
          </div>

          {filteredCountryList && filteredCountryList.length > 0 ? (
            filteredCountryList.map((country, index) => {
              return (
                <span key={`${country}_${index}`}>
                  <CountryList
                    allCountry={allCountry}
                    country={country}
                    handleCheckboxChange={handleCheckboxChange}
                    notWebsite={notWebsite}
                    isAndroid={isAndroid}
                    alerts={true}
                  />
                </span>
              );
            })
          ) : (
            <div className={styles.noResults}> {t('No countries found')} </div>
          )}
        </div>
      )}
      {notWebsite && filteredCountryList && filteredCountryList.length > 0 && (
        <>
          <div className={styles.countryListMobile}>
            {expand
              ? filteredCountryList?.map((country) => {
                  return (
                    <CountryList
                      allCountry={allCountry}
                      country={country}
                      handleCheckboxChange={handleCheckboxChange}
                      notWebsite={notWebsite}
                      isAndroid={isAndroid}
                    />
                  );
                })
              : filteredCountryList?.slice(0, 3)?.map((country) => {
                  return (
                    <CountryList
                      allCountry={allCountry}
                      country={country}
                      handleCheckboxChange={handleCheckboxChange}
                      notWebsite={notWebsite}
                      isAndroid={isAndroid}
                    />
                  );
                })}
          </div>
          {!expand && (
            <button className={styles.viewMoreBtn} onClick={handleExpand}>
              {t('view-more')} ({totalCountryLength - 3})
            </button>
          )}
        </>
      )}
    </div>
  );
}
