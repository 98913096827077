import { popUpData } from '../../../../utils/alertUtils';
import CrossIconMobile from '../../../IconComponents/CrossIconMobile';
import style from '../ManageAlertsPopup.module.css';

const SettingBulkAlertsWarning = ({
  type,
  alertsData,
  isPopUpVisible,
  updatePopUpVisibility,
  submitHandler,
  selectedAlertType,
  t,
}) => {
  return (
    <>
      <div className={style.modal}></div>
      <div
        className={`${style.deleteAllPopUpWrapper} ${
          isPopUpVisible ? style.showPopup : style.hidePopup
        }`}
      >
        <div className={style.deleteAllHeader}>
          <div> {t(popUpData?.[type]?.header)} </div>
          <button onClick={() => updatePopUpVisibility(false)}>
            <CrossIconMobile width="36" height="36" />
          </button>
        </div>
        <div className={style.deleteAllBody}>
          <span>{t(popUpData?.[type]?.['body'])}</span>
          <div className={style.deleteAllBtns}>
            <button onClick={() => updatePopUpVisibility(false)}> {t('cancel')} </button>
            <button
              onClick={
                type === 'delete'
                  ? alertsData?.length > 0
                    ? () => submitHandler(selectedAlertType)
                    : undefined
                  : submitHandler
              }
            >
              {t(popUpData?.[type]?.['submit'])}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingBulkAlertsWarning;
