import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './AlertSettingsTableData.module.css';
import DeleteIconAlerts from '../../../../assets/svgs/DeleteIconAlerts';
import { convertDateToString } from '../../../../utils/util';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import EditAlertMobile from '../../../../assets/svgs/EditAlertMobile';
import { alertPlatformsMapping, editAlertMobileConfig } from '../../../../utils/alertUtils';
import { useAlerts } from '../../../../hooks/useAlerts';
import { AlertSettingIndividualOptions } from './AlertSettingIndividualOptions';
import CrossIconMobile from '../../../IconComponents/CrossIconMobile';

const AlertSettingsTableData = (props) => {
  const { t } = useTranslation();
  const { locale, isIos, isAndroid } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const notWebsite = isIos || isAndroid;

  // Original and Edited Data
  const [alertData] = useState(props.data); // Original data
  const [editedAlertData, setEditedAlertData] = useState(props.data); // Editable data
  const editAlertActualDataRef = useRef(null);
  const [updateButtonEnabled, setUpdateButtonEnabled] = useState(false);
  const [eventName, setEventName] = useState({});
  const [eventAlertIndex, setEventAlertIndex] = useState(0);
  const [showEditAlertScreen, setShowEditAlertScreen] = useState(false);
  const settingsKeys = Object.keys(editAlertMobileConfig);
  const editAlertScreenRef = useRef(null);
  const { handleIndividualAlertDelete, handleIndividualAlertUpdate } = useAlerts();

  useEffect(() => {
    if (showEditAlertScreen) {
      document.addEventListener('mousedown', handleClickedOutside);
    } else {
      document.removeEventListener('mousedown', handleClickedOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickedOutside);
    };
  }, [showEditAlertScreen]);

  useEffect(() => {
    if (!editAlertActualDataRef.current) {
      editAlertActualDataRef.current = JSON.parse(JSON.stringify(props.data));
    }
  }, [props.data]);

  const handleClickedOutside = (event) => {
    if (
      editAlertScreenRef.current &&
      !editAlertScreenRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      setShowEditAlertScreen(false);
    }
  };

  // Validation Layer - Compare and call API
  const handleCheckboxChange = async (index, field) => {
    const updatedData = [...editedAlertData];

    if (field === 'reoccurring') {
      updatedData[index][field] = !updatedData[index][field];
    } else {
      const channelIndex = updatedData[index].notificationChannel.indexOf(field);
      if (channelIndex > -1) {
        updatedData[index].notificationChannel.splice(channelIndex, 1);
      } else {
        updatedData[index].notificationChannel.push(field);
      }
    }

    setEditedAlertData(updatedData);

    const isValid = validateChange(alertData[index], updatedData[index]);
    if (isValid) {
      await updateAlertRowData(updatedData[index]);
    } else {
      console.error('Validation failed for checkbox change');
    }
  };

  // Validation Logic
  const validateChange = (original, updated) => {
    if (updated.notificationChannel.length === 0) {
      return false;
    }
    return true;
  };

  // Simulated API Call
  const updateAlertRowData = async (updatedRow) => {
    handleIndividualAlertUpdate(updatedRow);
  };

  const handleEditAlert = (eventName, index) => {
    setEventName(eventName);
    setShowEditAlertScreen(true);
    setEventAlertIndex(index);
  };

  const closeEditAlert = () => {
    setEventName({});
    setShowEditAlertScreen(false);
  };

  const updateAlertField = (alertId, field, value) => {
    const updatedData = [...editedAlertData];
    if (field === 'alertRepeatitions') {
      updatedData[eventAlertIndex].reoccurring = !updatedData[eventAlertIndex].reoccurring;
    } else {
      const channelIndex = updatedData[eventAlertIndex].notificationChannel.indexOf(
        alertPlatformsMapping[value],
      );
      if (channelIndex > -1) {
        updatedData[eventAlertIndex].notificationChannel.splice(channelIndex, 1);
      } else {
        updatedData[eventAlertIndex].notificationChannel.push(alertPlatformsMapping[value]);
      }
    }
    setEditedAlertData(updatedData);
  };

  useEffect(() => {
    const currentData = editedAlertData?.[eventAlertIndex];
    const originalData = editAlertActualDataRef.current?.[eventAlertIndex];
    if (!currentData || !originalData) return; // Ensure data exists

    const hasChanges =
      (JSON.stringify(currentData?.notificationChannel) !==
        JSON.stringify(originalData?.notificationChannel) ||
        currentData?.reoccurring !== originalData?.reoccurring) &&
      currentData?.notificationChannel?.length;

    if (hasChanges && !updateButtonEnabled) {
      setUpdateButtonEnabled(true);
    } else if (!hasChanges && updateButtonEnabled) {
      setUpdateButtonEnabled(false);
    }
  }, [editedAlertData]);

  return (
    <div className={!notWebsite ? style.tableContainer : style.tableContainerMobile}>
      <table className={!notWebsite ? style.customTable : style.customTableMobile}>
        {!notWebsite && (
          <thead className={style.tableHead}>
            <tr className={style.tableRow}>
              <th>{t('Event Name')}</th>
              <th>{t('Date')}</th>
              <th>{t('Recurring')}</th>
              <th>{t('Platform')}</th>
              <th>{t('Push Notification')}</th>
              <th></th>
            </tr>
          </thead>
        )}
        <tbody>
          {editedAlertData &&
            editedAlertData.length > 0 &&
            editedAlertData.map((row, index) => (
              <tr className={notWebsite ? style.mobileRow : style.websiteRow} key={index}>
                <td>{t(row.ticker)}</td>
                <td className={notWebsite ? style.date : style.none}>
                  {notWebsite && row.date && <span> {t('Date')}: </span>}
                  {row.date &&
                    convertDateToString(
                      row.date,
                      timezoneOffset,
                      true,
                      locale !== 'en' ? locale : undefined,
                      t,
                      true,
                    )}
                </td>
                {!notWebsite && (
                  <>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.reoccurring}
                        onChange={() => handleCheckboxChange(index, 'reoccurring')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.notificationChannel?.includes('TRADING_PLATFORM')}
                        onChange={() => handleCheckboxChange(index, 'TRADING_PLATFORM')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={row.notificationChannel?.includes('PUSH')}
                        onChange={() => handleCheckboxChange(index, 'PUSH')}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => handleIndividualAlertDelete(row)}
                        className={style.deleteIcon}
                      >
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </>
                )}
                {notWebsite && (
                  <td>
                    <button className={style.editAlert} onClick={() => handleEditAlert(row, index)}>
                      <EditAlertMobile />
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {showEditAlertScreen && (
        <div>
          <div className={style.modal}></div>
          <div className={style.editAlertWrapper} ref={editAlertScreenRef}>
            <div className={isAndroid ? style.editAlertHeaderAndroid : style.editAlertHeader}>
              <span>{t(`${(eventName as { ticker: string })?.ticker}`)}</span>
              <button className={style.closeEditAlertBtn} onClick={closeEditAlert}>
                <CrossIconMobile />
              </button>
            </div>
            <div className={style.editAlertBody}>
              <AlertSettingIndividualOptions
                settingsKeys={settingsKeys}
                alertData={editedAlertData}
                eventAlertIndex={eventAlertIndex}
                updateAlertField={updateAlertField}
                t={t}
              />
            </div>
            <div className={style.buttonsWrapper}>
              <button
                className={isAndroid ? style.androidBtn : style.none}
                onClick={() => handleIndividualAlertDelete(eventName)}
              >
                {t('Delete alert')}
              </button>
              <button
                className={`${updateButtonEnabled ? style.buttonEnabled : style.buttonDisabled} ${isAndroid ? style.androidBtn : style.none}`}
                onClick={() => handleIndividualAlertUpdate(editedAlertData[eventAlertIndex])}
              >
                {t('Update changes')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertSettingsTableData;
