import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const AlertEnabledMobile = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'DARK' ? '#fff' : '#000';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6287 18.0526H19.3713C20.3968 18.0526 21 17.5502 21 16.7847C21 15.8517 20.0107 14.9904 19.1783 14.1531C18.6354 13.6148 18.4182 12.5024 18.3941 11.677C18.382 7.78947 17.4169 5.18182 14.8351 4.20096C14.437 3.00479 13.4477 2 12 2C10.5523 2 9.56301 3.00479 9.16487 4.20096C6.58311 5.18182 5.61797 7.78947 5.59383 11.677C5.58177 12.5024 5.35256 13.6148 4.82171 14.1531C3.97723 14.9904 3 15.8517 3 16.7847C3 17.5502 3.60322 18.0526 4.6287 18.0526ZM12 22C13.6528 22 14.8593 20.7919 14.9678 19.4522H9.03217C9.1287 20.7919 10.3472 22 12 22Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default AlertEnabledMobile;
