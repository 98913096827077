import { createContext, useContext, useEffect, useState } from 'react';
import { useSessionData } from './use-session-data';

import {
  alertMeOptionsMapping,
  alertPlatformsMapping,
  alertRepeatitionsMapping,
  alertSettingsConfigMobile,
} from '../utils/alertUtils';
import {
  deleteAllEconAlertsData,
  deleteAllEconBulkAlertsData,
  deleteEconAlertsData,
  deleteEconBulkAlertsData,
  getEconAlertsData,
  getEconAlertsSettings,
  getEconBulkAlertsData,
  postAlertSettingsData,
  postEconAlertsData,
  postEconBulkAlertsData,
  updateEconAlertsData,
} from '../api/EconCalendarAlertsApi';

interface AlertPreference {
  alertPlatforms?: string[]; // Assuming platform is an array of strings
  alertRepeatitions?: string; // Assuming repeations is a string
  alertMe?: string[];
}

type EconAlertContextType = {
  activeAlertTab: string;
  setActiveAlertTab: (data: string) => void;
  selectedAlertType: string;
  setSelectedAlertType: (alertType: string) => void;
  handleTriggerAlerts: (value) => void;
  selectedRowData: object;
  showAlertBox: boolean;
  setShowAlertBox: (value: boolean) => void;
  showManageAlerts: boolean;
  updateShowManageAlerts: (showStatus?: boolean) => void;
  userAlertPreference: AlertPreference;
  fetchAlertsSettings: () => void;
  handlePostAlertSettings: () => void;
  fetchAlertsData: (alertTab: string, selectedBulkAlertTab: string) => void;
  handleSetAlert: (data: Object, input: Object) => void;
  handleIndividualAlertUpdate: (data: Object) => void;
  alertsSettings: string[];
  setAlertsSettings: (value: []) => void;
  alertsData: object;
  macroAlertsData: object;
  setAlertsData: (value: {}) => void;
  handleIndividualAlertDelete: (data: Object) => void;
  handleDeleteAllAlerts: (selectedAlertType: string) => void;
  isAlertsLoading: boolean;
  setIsAlertsLoading: (value: boolean) => void;
  resetUserAlertPreference: () => void;
  viewSetBulkAlerts: boolean;
  updateSetBulkViewAlerts: () => void;
  handleDeleteEconBulkAlertsData: (data: Object, selectedAlertType: string) => void;
  closeAlerts: () => void;
  updateUserAlertPreferences: (value: string) => void;
  showDefaultSettingsMobile: boolean;
  updateShowDefaultSettingsMobile: (value) => void;
  submitBulkAlert: (payload: Object) => void;
  tabAlertData: string[];
  showBulkAlertsDrawer: boolean;
  setShowBulkAlertsDrawer: (value: boolean) => void;
  isAlertsEnabled: boolean;
};

export const EconAlertContext = createContext<EconAlertContextType | null>(null);

export function useAlerts() {
  return useContext(EconAlertContext);
}

export const EconAlertProvider = ({ children, isAlertsEnabled, alertsApiUrl }) => {
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [activeAlertTab, setActiveAlertTab] = useState('alertSettings');
  const [showManageAlerts, setShowManageAlerts] = useState(false);
  const [userAlertPreference, setUserAlertPreference] = useState<AlertPreference>({});
  const [alertsSettings, setAlertsSettings] = useState([]);
  const [alertsData, setAlertsData] = useState({});
  const [macroAlertsData, setMacroAlertsData] = useState([]);
  const [tabAlertData, setTabAlertData] = useState([]);
  const [isAlertsLoading, setIsAlertsLoading] = useState(false);
  const [viewSetBulkAlerts, setViewSetBulkAlerts] = useState(false);
  const [selectedAlertType, setSelectedAlertType] = useState('macroeconomicEvents');
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showDefaultSettingsMobile, setShowDefaultSettingsMobile] = useState(false);
  const [showBulkAlertsDrawer, setShowBulkAlertsDrawer] = useState(false);

  const { cst } = useSessionData();

  useEffect(() => {
    if (!showBulkAlertsDrawer) {
      if (activeAlertTab === 'alertSettings') {
        setTabAlertData(alertsData?.[activeAlertTab]);
      } else {
        setTabAlertData(alertsData?.[selectedAlertType]);
      }
    }
  }, [showBulkAlertsDrawer, alertsData, activeAlertTab, selectedAlertType]);

  const updateShowDefaultSettingsMobile = (value) => {
    setShowDefaultSettingsMobile(value);
  };

  const updateShowManageAlerts = (showStatus = false) => {
    setShowManageAlerts(showStatus);
  };

  const updateSetBulkViewAlerts = () => {
    setViewSetBulkAlerts((prev) => !prev);
  };

  const resetUserAlertPreference = () => {
    setUserAlertPreference({});
  };

  const updateUserAlertPreferences = (value: string) => {
    setUserAlertPreference((prev) => {
      const updatedPreferences: AlertPreference = { ...prev };
      if (alertSettingsConfigMobile.alertPlatforms.options.includes(value)) {
        const updatedPlatform = updatedPreferences.alertPlatforms
          ? [...updatedPreferences.alertPlatforms]
          : [];
        if (updatedPlatform.includes(value)) {
          updatedPreferences.alertPlatforms = updatedPlatform.filter(
            (platform) => platform !== value,
          );
        } else {
          updatedPlatform.push(value);
          updatedPreferences.alertPlatforms = updatedPlatform;
        }
      } else if (alertSettingsConfigMobile.alertRepeatitions.options.includes(value)) {
        updatedPreferences.alertRepeatitions =
          updatedPreferences.alertRepeatitions === value ? undefined : value;
      } else if (alertSettingsConfigMobile.alertMe.options.includes(value)) {
        const updatedAlertMe = updatedPreferences.alertMe ? [...updatedPreferences.alertMe] : [];
        if (updatedAlertMe.includes(value)) {
          updatedPreferences.alertMe = updatedAlertMe.filter((option) => option !== value);
        } else {
          updatedAlertMe.push(value);
          updatedPreferences.alertMe = updatedAlertMe;
        }
      }

      return updatedPreferences;
    });
  };

  const handleSetAlert = async (data, input) => {
    try {
      const payload = {
        ticker: data.ticker,
        eventName: data.event,
        calendarId: data.calendarId,
        date: data.date,
        reoccurring: input.alertRepeatitions === 'Every time',
        notificationChannel: input.alertPlatforms.map((item) => alertPlatformsMapping[item]),
      };
      const resp = await postEconAlertsData(payload, cst, alertsApiUrl);
      if (resp.status === 200) {
        fetchAlertsData(activeAlertTab, selectedAlertType);
        setShowAlertBox(false);
      }
    } catch (error) {
      console.error(error, 'error setting alert');
    }
  };

  const handleTriggerAlerts = (data) => {
    setSelectedRowData(data);
    //@ts-ignore
    if (selectedRowData?.id !== data?.id) {
      setSelectedRowData(data);
      setShowAlertBox(true);
    } else {
      setShowAlertBox(false);
      setSelectedRowData({});
    }
  };

  const closeAlerts = () => {
    setSelectedRowData({});
    setShowAlertBox(false);
  };

  const fetchAlertsSettings = async () => {
    try {
      setIsAlertsLoading(true);
      setAlertsSettings([]);
      const resp = await getEconAlertsSettings(cst, alertsApiUrl);
      const responseData = await resp.json();

      if (resp.status === 200) {
        setAlertsSettings(responseData);
        const alertPreferenceData = responseData;
        setUserAlertPreference({
          alertMe: alertSettingsConfigMobile.alertMe.options.filter((item) =>
            alertPreferenceData.minutesInAdvanceToNotify?.includes(alertMeOptionsMapping[item]),
          ),
          alertPlatforms: alertSettingsConfigMobile.alertPlatforms.options.filter((item) =>
            alertPreferenceData.notificationChannel?.includes(alertPlatformsMapping[item]),
          ),
          alertRepeatitions: alertSettingsConfigMobile.alertRepeatitions.options.filter(
            (item) => alertPreferenceData.reoccurring === alertRepeatitionsMapping[item],
          )[0],
        });
      }
    } catch (error) {
      console.error(error, 'error fetching alerts');
    } finally {
      setIsAlertsLoading(false);
    }
  };

  const handlePostAlertSettings = async () => {
    const minutesInAdvanceToNotify = userAlertPreference.alertMe.map(
      (item) => alertMeOptionsMapping[item],
    );
    const notificationChannel = userAlertPreference.alertPlatforms.map(
      (item) => alertPlatformsMapping[item],
    );
    const reoccurring = userAlertPreference.alertRepeatitions !== 'Only once';
    try {
      let payload = {
        minutesInAdvanceToNotify: minutesInAdvanceToNotify,
        reoccurring: reoccurring,
        notificationChannel: notificationChannel,
      };
      const resp = await postAlertSettingsData(payload, cst, alertsApiUrl);
      if (resp.status === 200) {
        await alertsData['alertSettings'].forEach((data, index) => {
          const updatedData = {
            ...data,
            reoccurring: reoccurring,
            notificationChannel: notificationChannel,
          };
          handleIndividualAlertUpdate(updatedData);
        });
        fetchAlertsData(activeAlertTab, selectedAlertType);
      }
    } catch (error) {
      console.log(error, 'Err');
      updateShowManageAlerts(false);
    } finally {
      updateShowManageAlerts();
    }
  };

  const fetchAlertsData = async (activeTab, selectedBulkAlertTab) => {
    if (activeTab === 'alertSettings') {
      try {
        setIsAlertsLoading(true);
        setAlertsData([]);
        const resp = await getEconAlertsData(cst, alertsApiUrl);
        const responseData = await resp.json();

        if (resp.status === 200) {
          setAlertsData({ [activeTab]: responseData });
          setMacroAlertsData(responseData);
        }
      } catch (error) {
        console.error(error, 'error fetching alerts');
      } finally {
        setIsAlertsLoading(false);
      }
    } else {
      try {
        setIsAlertsLoading(true);
        setAlertsData({});

        const tabsToFetch = ['macroeconomicEvents', 'earningsEvents'];
        const dataPromises = tabsToFetch.map((tab) =>
          getEconBulkAlertsData(cst, alertsApiUrl, tab).then(async (resp) => {
            const responseData = await resp.json();
            // Return data only if it's non-empty and the status is 200
            return resp.status === 200 && Object.keys(responseData).length > 0
              ? { [tab]: responseData }
              : null;
          }),
        );

        const results = await Promise.all(dataPromises);

        // Filter out null values (empty or invalid data)
        const aggregatedData = results
          .filter(Boolean)
          .reduce((acc, data) => ({ ...acc, ...data }), {});

        setAlertsData(aggregatedData);
      } catch (error) {
        console.error(error, 'error fetching alerts');
      } finally {
        setIsAlertsLoading(false);
      }
    }
  };

  const submitBulkAlert = async (payload) => {
    try {
      const resp = await postEconBulkAlertsData(payload, cst, alertsApiUrl, selectedAlertType);
      if (resp.status === 200) {
        fetchAlertsData(activeAlertTab, selectedAlertType);
      }
    } catch (error) {
      console.log(error, 'err');
    }
  };

  const handleDeleteEconBulkAlertsData = async (data, selectedAlertType) => {
    try {
      const resp = await deleteEconBulkAlertsData(data, cst, alertsApiUrl, selectedAlertType);

      if (resp.status === 200) {
        fetchAlertsData(activeAlertTab, selectedAlertType);
      }
    } catch (error) {
      console.log(error, 'error deleting alert');
    }
  };

  const handleIndividualAlertDelete = async (data) => {
    try {
      const resp = await deleteEconAlertsData(data.alertId, cst, alertsApiUrl);

      if (resp.status === 200) {
        fetchAlertsData(activeAlertTab, selectedAlertType);
      }
    } catch (error) {
      console.log(error, 'error deleting alert');
    }
  };

  const handleIndividualAlertUpdate = async (data) => {
    try {
      const resp = await updateEconAlertsData(data, cst, alertsApiUrl);

      if (resp.status === 200) {
        fetchAlertsData(activeAlertTab, selectedAlertType);
      }
    } catch (error) {
      console.log(error, 'error deleting alert');
    }
  };

  const handleDeleteAllAlerts = async (selectedAlertType) => {
    if (activeAlertTab === 'alertSettings') {
      try {
        const resp = await deleteAllEconAlertsData(cst, alertsApiUrl);
        if (resp.status === 200) {
          fetchAlertsData(activeAlertTab, selectedAlertType);
        }
      } catch (error) {
        console.error(error, 'error deleting alerts');
      }
    } else {
      try {
        const resp = await deleteAllEconBulkAlertsData(cst, alertsApiUrl, selectedAlertType);
        if (resp.status === 200) {
          fetchAlertsData(activeAlertTab, selectedAlertType);
        }
      } catch (error) {
        console.error(error, 'error deleting alerts');
      }
    }
  };

  return (
    <EconAlertContext.Provider
      value={{
        activeAlertTab,
        setActiveAlertTab,
        selectedAlertType,
        setSelectedAlertType,
        handleTriggerAlerts,
        selectedRowData,
        showAlertBox,
        setShowAlertBox,
        showManageAlerts,
        updateShowManageAlerts,
        userAlertPreference,
        fetchAlertsSettings,
        handlePostAlertSettings,
        fetchAlertsData,
        handleSetAlert,
        handleIndividualAlertUpdate,
        alertsSettings,
        setAlertsSettings,
        alertsData,
        setAlertsData,
        handleDeleteAllAlerts,
        handleIndividualAlertDelete,
        isAlertsLoading,
        setIsAlertsLoading,
        resetUserAlertPreference,
        viewSetBulkAlerts,
        updateSetBulkViewAlerts,
        handleDeleteEconBulkAlertsData,
        closeAlerts,
        updateUserAlertPreferences,
        showDefaultSettingsMobile,
        updateShowDefaultSettingsMobile,
        submitBulkAlert,
        tabAlertData,
        showBulkAlertsDrawer,
        setShowBulkAlertsDrawer,
        isAlertsEnabled,
        macroAlertsData,
      }}
    >
      {children}
    </EconAlertContext.Provider>
  );
};
