import { useEffect, useRef, useState } from 'react';
import style from './BulkAlertSettings.module.css';
import styles from './BulkAlertsTabs.module.css';
import CustomDateInput from './CustomDateInput';

const CustomDateInputWrapper = ({
  notWebsite,
  selectedDate,
  formatDate,
  setSelectedDate,
  isAndroid,
  isIos,
  t,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false); // Toggle calendar visibility
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateCalendarVisibility = (value) => {
    setIsCalendarOpen(value);
  };

  return (
    <div
      className={
        notWebsite
          ? isAndroid
            ? style.flexSectionDateAndroid
            : style.flexSectionDateMobile
          : style.gridSection
      }
    >
      <div
        className={notWebsite ? style.mobileDateWrapper : style.dateWrapper}
        onClick={() => updateCalendarVisibility(true)}
        tabIndex={0}
        role="button"
      >
        {((selectedDate === null && notWebsite) || !notWebsite) && (
          <p
            className={`${notWebsite ? `${style.textMobile} ${style.textMobileEndDate}` : style.gridText} ${isAndroid ? style.androidText : isIos ? style.iosText : style.none}`}
          >
            {t('End Date')}
          </p>
        )}
        {selectedDate && notWebsite && (
          <div className={isAndroid ? styles.selectedDateMobileAndroid : styles.selectedDateMobile}>
            <span className={isAndroid ? styles.endDateMobileAndroid : styles.endDateMobile}>
              {t('End Date')}
            </span>
            <span className={isAndroid ? styles.formattedDateAndroid : styles.formattedDateIos}>
              {formatDate(selectedDate, true)}
            </span>
          </div>
        )}
        <CustomDateInput
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          notWebsite={notWebsite}
          formatDate={formatDate}
          isCalendarOpen={isCalendarOpen}
          updateCalendarVisibility={updateCalendarVisibility}
          dropdownRef={dropdownRef}
          t={t}
        />
      </div>
    </div>
  );
};

export default CustomDateInputWrapper;
