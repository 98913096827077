import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

const CalendarIconMobile = () => {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme ? '#000' : '#fff';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.34167 17.3003C8.34167 18.217 9.08333 18.9587 10 18.9587C10.9167 18.9587 11.6583 18.217 11.6583 17.3003H8.34167ZM10 4.79199C12.3 4.79199 14.1667 6.65866 14.1667 8.95866V14.792H5.83333V8.95866C5.83333 6.65866 7.7 4.79199 10 4.79199ZM10 1.04199C9.30833 1.04199 8.75 1.60033 8.75 2.29199V3.26699C6.13333 3.83366 4.16667 6.16699 4.16667 8.95866V13.9587L2.5 15.6253V16.4587H17.5V15.6253L15.8333 13.9587V8.95866C15.8333 6.16699 13.8667 3.83366 11.25 3.26699V2.29199C11.25 1.60033 10.6917 1.04199 10 1.04199ZM10.8333 6.45866H9.16667V8.95866H6.66667V10.6253H9.16667V13.1253H10.8333V10.6253H13.3333V8.95866H10.8333V6.45866Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default CalendarIconMobile;
