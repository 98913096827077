import style from './ImportanceBadge.module.css';

export default function ImportanceBadge({ text, t, bulkAlerts = false }) {
  return (
    <div
      className={`${style.badgeImportance} ${text?.toLowerCase() === 'high' ? style.highImp : text?.toLowerCase() === 'low' ? style.lowImp : style.medImp} ${bulkAlerts ? style.bulkAlertImp : style.none}`}
    >
      {bulkAlerts ? t(text.toLowerCase()).toUpperCase() : t(text?.toLowerCase()?.[0]).toUpperCase()}
    </div>
  );
}
