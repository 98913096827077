// @ts-nocheck
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { alertPlatformsMapping, alertSettingsConfigMobile } from '../../../../utils/alertUtils';
import style from './AlertSettings.module.css';
import styles from './AlertInput.module.css';

export const AlertSettingIndividualOptions = ({
  settingsKeys,
  alertData,
  eventAlertIndex,
  updateAlertField,
  t,
}) => {
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  const editedAlertData = alertData[eventAlertIndex];
  return (
    <>
      {settingsKeys.map((item) => {
        return (
          <div className={notWebsite ? style.indAlertBoxMobile : style.indAlertBox} key={item}>
            <p className={notWebsite ? style.alertTextMobile : style.alertText}>
              {t(alertSettingsConfigMobile[item].text)}
            </p>
            <div className={notWebsite ? style.alertItemsMobile : style.alertItems}>
              {alertSettingsConfigMobile[item].options.map((option) => {
                const isChecked =
                  (item === 'alertRepeatitions' &&
                    editedAlertData.reoccurring === (option === 'Every time')) ||
                  (item === 'alertPlatforms' &&
                    editedAlertData.notificationChannel?.includes(alertPlatformsMapping[option]));

                return (
                  <div
                    key={option}
                    className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${notWebsite ? (isIos ? styles.inputStylingMobile : styles.inputStylingAndroid) : styles.inputStyling} ${isAndroid ? style.alertItemsMobileAndroid : style.none}`}
                  >
                    <div>
                      <input
                        type={item === 'alertRepeatitions' ? 'radio' : 'checkbox'}
                        id={`${editedAlertData.alertId}-${option}`}
                        name={item}
                        checked={isChecked}
                        onChange={() => {
                          updateAlertField(eventAlertIndex, item, option);
                        }}
                      />
                    </div>
                    <label htmlFor={`${editedAlertData.alertId}-${option}`}>{t(option)}</label>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
