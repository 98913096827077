import { useEconCalendar } from '../../hooks/useEconCalendar';
import style from '../../styles/filters/ZoneCountryImportance.module.css';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import CountryFilterBox from './CountryFilterBox';
import ImportanceFilterBox from './ImportanceFilterBox';
import ManageAlertsPopup from '../Alerts/ManageAlerts/ManageAlertsPopup';
import { useAlerts } from '../../hooks/useAlerts';
import { useTranslation } from 'react-i18next';

const ZoneCountryImportance = () => {
  const {
    activeTab,
    allCountry,
    extendedImportanceFilter,
    updateExtendedImportanceFilter,
    handleImportance,
    extendedCountryFilter,
    updateExtendedCountryFilter,
    selectedImportance,
  } = useEconCalendar();

  const { showManageAlerts, updateShowManageAlerts, isAlertsEnabled } = useAlerts();
  const toShowImportanceFilterSection = ['events', 'earnings-reports'].includes(activeTab);
  const isMobile = useScreenDimensions();
  const { isIos, isAndroid, isWebsite } = useEconCalendarParams();
  const { t } = useTranslation();

  const manageAlerts = () => {
    updateShowManageAlerts(!showManageAlerts);
  };

  return (
    <>
      {!isAndroid && (
        <div
          className={`${style.zoneCountryImportance} ${
            isMobile && isIos
              ? style.zoneCountryImportanceIos
              : isMobile && isWebsite
                ? style.websiteResponsiveFilterBtn
                : style.none
          }`}
        >
          {toShowImportanceFilterSection && (
            <ImportanceFilterBox
              selectedImportance={selectedImportance}
              extendedImportanceFilter={extendedImportanceFilter}
              updateExtendedImportanceFilter={updateExtendedImportanceFilter}
              handleImportance={handleImportance}
              showBulkAlertsDrawer={false}
            />
          )}
          {toShowImportanceFilterSection && isMobile && isIos && (
            <div className={style.separator}></div>
          )}
          <CountryFilterBox
            allCountry={allCountry}
            extendedCountryFilter={extendedCountryFilter}
            updateExtendedCountryFilter={updateExtendedCountryFilter}
          />
        </div>
      )}
      {isIos && ['events', 'earnings-reports'].includes(activeTab) && isAlertsEnabled && (
        <div className={style.manageAlertContainer}>
          <button className={style.manageAlertsButton} onClick={manageAlerts}>
            <span id="alertSettingsEcon"> {t('Manage Alerts')} </span>
          </button>
          {showManageAlerts && <ManageAlertsPopup />}
        </div>
      )}
    </>
  );
};

export default ZoneCountryImportance;
