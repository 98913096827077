export const alertConfiguration = {
  alertPlatforms: {
    text: 'Alert me via',
    options: [['Trading Platform'], ['Push Notification']],
  },
  alertRepeatitions: {
    text: 'Recurring',
    options: [
      ['Every time', 'Notify each time this event occurs'],
      ['Only once', 'Notify for this event only once'],
    ],
  },
};

export const alertConfigurationMobile = {
  alertPlatforms: {
    text: 'Alert me via',
    options: [['Web Platform'], ['Push Notification']],
  },
  alertRepeatitions: {
    text: 'Recurring',
    options: [
      ['Every time', 'Notify each time this event occurs'],
      ['Only once', 'Notify for this event only once'],
    ],
  },
};

export const alertSettingsConfigMobile = {
  alertMe: {
    text: 'Alert me',
    options: ['At time of event', '5 mins before', '15 mins before', '60 mins before'],
  },
  alertPlatforms: {
    text: 'Alert me via',
    options: ['Web Platform', 'Push Notification'],
  },
  alertRepeatitions: {
    text: 'Recurring',
    options: ['Every time', 'Only once'],
  },
};

export const editAlertMobileConfig = {
  alertPlatforms: {
    text: 'Alert me via',
    options: ['Web Platform', 'Push Notification'],
  },
  alertRepeatitions: {
    text: 'Recurring',
    options: ['Every time', 'Only once'],
  },
};

export const alertMeOptionsMapping = {
  'At time of event': 0,
  '5 mins before': 5,
  '15 mins before': 15,
  '60 mins before': 60,
};

export const alertPlatformsMapping = {
  'Trading Platform': 'TRADING_PLATFORM',
  'Web Platform': 'TRADING_PLATFORM',
  'Push Notification': 'PUSH',
};

export const alertRepeatitionsMapping = {
  'Every time': true,
  'Only once': false,
};

export const noEventScreenMobileConfig = {
  bulkAlerts: {
    heading: 'No bulk alerts to display',
    body: 'Set bulk alerts to stay informed about multiple events at once',
  },
  alerts: {
    heading: 'No alerts set yet',
    body: 'Create alerts to stay informed about key events and never miss an update.',
  },
};

export const bulkAlertMacroTableRow = {
  countryRegion: 'Country / Region',
  importance: 'Importance',
  endDate: 'End Date',
  lastTriggered: 'Last Triggered',
  delete: 'Delete',
};

export const bulkAlertEarningsTableRow = {
  company: 'Company',
  endDate: 'End Date',
  lastTriggered: 'Last Triggered',
  delete: 'Delete',
};

export const bulkAlertTabs = {
  macroeconomicEvents: 'Macroeconomic Events',
  earningsEvents: 'Earnings Events',
};

export const popUpData = {
  delete: {
    header: 'Remove all events',
    body: 'This will delete all your alerts. This cannot be undone. Are you sure you want to proceed?',
    submit: 'Delete all',
  },
  warning: {
    header: 'Bulk alerts confirmation',
    body: 'The settings you have selected will result in you receiving a high number of alerts. Do you wish to continue?',
    submit: 'Continue',
  },
};
