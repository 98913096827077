import ExpandFiltersIcon from '../IconComponents/ExpandFiltersIcon';
import ImportanceFilter from './ImportanceFilter';
import style from '../../styles/filters/ZoneCountryImportance.module.css';
import { useEconCalendar } from '../../hooks/useEconCalendar';
import useScreenDimensions from '../../hooks/useScreenDimension';
import { useTranslation } from 'react-i18next';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import ArrowExpandIcon from '../../assets/svgs/ArrowExpandIcon';
import EventCollapse from '../../assets/svgs/EventCollapse';
import { importanceLevels } from '../../utils/util';
import CustomCheckbox from './CustomCheckbox';
import Badge from '../Global/Badge';

export default function ImportanceFilterBox({
  selectedImportance,
  extendedImportanceFilter,
  updateExtendedImportanceFilter,
  handleImportance,
  showBulkAlertsDrawer,
  isAlert = false,
}) {
  const { isDataLoading } = useEconCalendar();
  const isMobile = useScreenDimensions();
  const { t } = useTranslation();
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;

  return (
    <div className={style.filtersWrapper}>
      {(!isAlert || (isAlert && !notWebsite)) && (
        <button
          className={`${style.alignment} ${
            isDataLoading ? style.noCursor : style.cursor
          } importanceFilter ${isAlert && style.alertFilterWrapper}`}
          onClick={updateExtendedImportanceFilter}
          data-testid="importance_filter_expand"
          disabled={isDataLoading}
        >
          <div
            className={`${style.importance} ${style.filterHeading} ${
              isMobile && isIos ? style.iosFilterSection : style.none
            }`}
          >
            <div> {t('Importance')} </div>
            {isIos && isMobile && (
              <div className={style.iosAllText}>
                {!isAlert && (
                  <span>
                    {selectedImportance.length === 3
                      ? t('All')
                      : selectedImportance.length + ' / 3'}
                  </span>
                )}
                {extendedImportanceFilter ? <ArrowExpandIcon /> : <EventCollapse />}
              </div>
            )}
            {!isMobile && (
              <div className={`${isMobile ? style.mobileFlags : style.importanceLevels}`}>
                <span
                  className={
                    !isMobile && !selectedImportance?.includes('high')
                      ? style.notSelected
                      : style.highImportance
                  }
                >
                  <p> {t('H')} </p>
                </span>
                <span
                  className={
                    !isMobile && !selectedImportance?.includes('medium')
                      ? style.notSelected
                      : style.mediumImportance
                  }
                >
                  <p> {t('M')} </p>
                </span>
                <span
                  className={
                    !isMobile && !selectedImportance?.includes('low')
                      ? style.notSelected
                      : style.lowImportance
                  }
                >
                  <p> {t('L')} </p>
                </span>
              </div>
            )}
          </div>
          {!isMobile && <ExpandFiltersIcon />}
        </button>
      )}
      {extendedImportanceFilter && (
        <div
          className={`${!isMobile ? style.importanceFilter : style.none} ${
            !isMobile ? style.showFilterImportance : style.showFilterImportanceIos
          }`}
        >
          {
            <ImportanceFilter
              selectedImportance={selectedImportance}
              extendedImportanceFilter={extendedImportanceFilter}
              updateExtendedImportanceFilter={updateExtendedImportanceFilter}
              handleImportance={handleImportance}
            />
          }
        </div>
      )}
      {notWebsite && showBulkAlertsDrawer && (
        <div className={style.importanceContainer}>
          {importanceLevels.map((importance, index) => (
            <div
              className={`${style.importanceWrapper} ${isAndroid && style.androidDirection}`}
              key={`${index}_${importance}`}
            >
              <div className={style.inputBox}>
                <CustomCheckbox
                  onClickHandler={handleImportance}
                  value={importance}
                  checked={selectedImportance?.includes(importance)}
                />
              </div>
              <div
                className={`${style.importanceText} ${isAndroid && style.androidDirection}`}
                onClick={() => handleImportance(importance)}
              >
                <span className={style.importanceLabel}> {t(importance)} </span>
                <Badge text={importance} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
