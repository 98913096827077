import { useState } from 'react';
import style from './BulkAlertSettings.module.css';
import styles from '../AlertSettings/AlertInput.module.css';
import BulkAlertSettingsTableData from './BulkAlertSettingsTableData';
import BulkAlertsTabs from './BulkAlertsTabs';
import { useAlerts } from '../../../../hooks/useAlerts';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import NoAlertsSetMobile from '../NoAlertsSetMobile';
import BackArrow from '../../../../assets/svgs/BackArrow';
import { bulkAlertEarningsTableRow, bulkAlertMacroTableRow } from '../../../../utils/alertUtils';
import { formatDate } from '../../../../utils/util';
import BulkAlertMobileData from './BulkAlertMobileData';
import BackArrowiOS from '../../../../assets/svgs/BackArrowiOS';
import { useTranslation } from 'react-i18next';
import SettingBulkAlertsWarning from './SettingBulkAlertsWarning';

export default function AlertSettings({ activeAlertTab }) {
  const [selectedImportance, setSelectedImportance] = useState([]);
  const [extendedImportanceFilter, setExtendedImportanceFilter] = useState(false);
  const [allCountry, setAllCountry] = useState([]);
  const [showBulkAlertSettingWarning, setShowBulkAlertSettingWarning] = useState(false);

  const {
    isAlertsLoading,
    tabAlertData,
    alertsData,
    selectedAlertType,
    setSelectedAlertType,
    showBulkAlertsDrawer,
    setShowBulkAlertsDrawer,
  } = useAlerts();
  const { t } = useTranslation();
  const { isAndroid, isIos } = useEconCalendarParams();
  const notWebsite = isAndroid || isIos;

  const [companyName, setCompanyName] = useState('');
  const { submitBulkAlert } = useAlerts();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // State to store the selected date

  const handleImportance = (value) => {
    if (selectedImportance.includes(value)) {
      setSelectedImportance(selectedImportance?.filter((item) => item !== value));
    } else {
      setSelectedImportance((prevState) => [...prevState, value]);
    }
  };

  const handleAlertTypeChange = (e) => {
    setSelectedAlertType(e.target.value);
    setSelectedDate(null);
  };

  const rowsInfo =
    selectedAlertType === 'macroeconomicEvents'
      ? bulkAlertMacroTableRow
      : bulkAlertEarningsTableRow;

  const handleSubmitBulkAlert = () => {
    if (showBulkAlertsDrawer || !notWebsite) {
      if (selectedAlertType === 'macroeconomicEvents') {
        // handle macroeconomic events bulk alerts
        allCountry.forEach((country, index) => {
          const payload = {
            importance: selectedImportance.map((item) => item.toUpperCase()),
            countryRegion: [country],
            endDate: selectedDate && formatDate(selectedDate),
          };
          submitBulkAlert(payload);
        });
      } else if (selectedAlertType === 'earningsEvents') {
        const payload = {
          company: companyName,
          endDate: selectedDate && formatDate(selectedDate),
        };
        submitBulkAlert(payload);
      }
      setShowBulkAlertsDrawer(false);
      showBulkAlertSettingWarning && setShowBulkAlertSettingWarning(false);
    } else {
      setShowBulkAlertsDrawer(true);
    }
  };

  const multipleAlertsSet = tabAlertData?.length > 1;
  const activeSelection =
    activeAlertTab === 'alertSettings'
      ? multipleAlertsSet
        ? 'economic events'
        : 'economic event'
      : selectedAlertType === 'macroeconomicEvents'
        ? 'macroeconomic events'
        : 'earnings events';

  const eventLength = tabAlertData?.length ? `${tabAlertData?.length} ` : '0 ';

  return (
    <div
      className={
        showBulkAlertsDrawer && notWebsite
          ? style.bulkAlertSettingsDrawer
          : style.bulkAlertSettingsContainer
      }
    >
      {!showBulkAlertsDrawer && notWebsite && !Object.keys(alertsData).length ? (
        <NoAlertsSetMobile config="bulkAlerts" />
      ) : (
        ((showBulkAlertsDrawer && notWebsite) || !notWebsite) && (
          <>
            {showBulkAlertsDrawer && notWebsite && (
              <div className={`${style.header} ${isAndroid ? style.androidHeader : style.none}`}>
                <button
                  className={`${style.backArrow} ${isIos ? style.backArrowIos : style.none}`}
                  onClick={() => setShowBulkAlertsDrawer(false)}
                >
                  {isIos ? <BackArrowiOS width="12" height="18" /> : <BackArrow />}
                </button>
                <span className={isAndroid ? style.bulkAlertsTextAndroid : style.bulkAlertsText}>
                  {t('Bulk Alerts')}
                </span>
              </div>
            )}
            <div
              className={
                notWebsite ? style.drawerBodySectionWrapper : style.drawerBodySectionWrapperWeb
              }
            >
              <div className={notWebsite ? style.drawerBodySection : style.drawerBodySectionWeb}>
                <p className={style.bulkAlertText}>{t('Set bulk alerts for')}</p>
                <div className={notWebsite ? style.bulkAlertSectionMobile : style.bulkAlertSection}>
                  <div
                    className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection} ${notWebsite ? styles.inputStylingMobile : styles.inputStyling}`}
                  >
                    <div>
                      <input
                        type="radio"
                        id="macroeconomicEvents"
                        name="alertType"
                        value="macroeconomicEvents"
                        checked={selectedAlertType === 'macroeconomicEvents'}
                        onChange={handleAlertTypeChange}
                      />
                    </div>
                    <label
                      htmlFor="macroeconomicEvents"
                      className={`${notWebsite ? style.labelTextMobile : style.labelText} ${isAndroid ? style.androidLabel : style.none}`}
                    >
                      {t('Macroeconomic Events')}
                    </label>
                  </div>
                  <div
                    className={`${notWebsite ? style.selectionItemMobile : style.selectionItem} ${isAndroid && style.androidDirection} ${notWebsite ? styles.inputStylingMobile : styles.inputStyling}`}
                  >
                    <div>
                      <input
                        type="radio"
                        id="earningsEvents"
                        name="alertType"
                        value="earningsEvents"
                        checked={selectedAlertType === 'earningsEvents'}
                        onChange={handleAlertTypeChange}
                      />
                    </div>
                    <label
                      htmlFor="earningsEvents"
                      className={`${notWebsite ? style.labelTextMobile : style.labelText} ${isAndroid ? style.androidLabel : style.none}`}
                    >
                      {t('Earnings Events')}
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <BulkAlertsTabs
                  selectedImportance={selectedImportance}
                  extendedImportanceFilter={extendedImportanceFilter}
                  setExtendedImportanceFilter={setExtendedImportanceFilter}
                  handleImportance={handleImportance}
                  allCountry={allCountry}
                  setAllCountry={setAllCountry}
                  selectedAlertType={selectedAlertType}
                  showBulkAlertsDrawer={showBulkAlertsDrawer}
                  setShowBulkAlertSettingWarning={setShowBulkAlertSettingWarning}
                  formatDate={formatDate}
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
          </>
        )
      )}
      {notWebsite && (
        <div className={style.setBulkAlertsWrapper}>
          <button
            className={`${style.bulkAlertBtn} ${isAndroid ? style.bulkAlertBtnAndroid : style.none}`}
            onClick={handleSubmitBulkAlert}
          >
            {t('Set bulk alerts')}
          </button>
        </div>
      )}
      {!isAlertsLoading && !showBulkAlertsDrawer ? (
        !notWebsite ? (
          <div className={style.alertDetailContainer}>
            <p className={style.alertDetailText}>
              {t('You have alerts set for')} {eventLength}
              {t(activeSelection)}
            </p>
            {tabAlertData && tabAlertData.length > 0 ? (
              <BulkAlertSettingsTableData
                data={alertsData}
                rowsInfo={rowsInfo}
                selectedAlertType={selectedAlertType}
              />
            ) : (
              <div className={style.noEventsDiv}>
                <p className={style.noEventsToDisplay}>{t('No events to display')}</p>
              </div>
            )}
          </div>
        ) : (
          <BulkAlertMobileData data={alertsData} />
        )
      ) : (
        !showBulkAlertsDrawer && <p className={style.alertDetailText}>{t('Loading page')}</p>
      )}

      {!isAlertsLoading && !showBulkAlertsDrawer && showBulkAlertSettingWarning && (
        <SettingBulkAlertsWarning
          type="warning"
          alertsData={alertsData}
          isPopUpVisible={showBulkAlertSettingWarning}
          updatePopUpVisibility={setShowBulkAlertSettingWarning}
          submitHandler={handleSubmitBulkAlert}
          selectedAlertType={selectedAlertType}
          t={t}
        />
      )}
    </div>
  );
}
