import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';

export default function BulkAlertDelete({ platform, width = '24', height = '24' }) {
  const { currentTheme } = useEconCalendarParams();
  const fillColor = currentTheme === 'dark' ? '#a8a8a8' : '#6F6F6F';

  return platform === 'android' ? (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 4.5H19V6.5H5V4.5H8.5L9.5 3.5H14.5L15.5 4.5ZM8 21.5005C6.9 21.5005 6 20.6005 6 19.5005V7.50049H18V19.5005C18 20.6005 17.1 21.5005 16 21.5005H8Z"
        fill={fillColor}
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="delete">
        <path
          id="Vector"
          d="M10.6673 6V12.6667H5.33398V6H10.6673ZM9.66732 2H6.33398L5.66732 2.66667H3.33398V4H12.6673V2.66667H10.334L9.66732 2ZM12.0007 4.66667H4.00065V12.6667C4.00065 13.4 4.60065 14 5.33398 14H10.6673C11.4007 14 12.0007 13.4 12.0007 12.6667V4.66667Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
