import { useEffect, useRef, useState } from 'react';
import style from './AlertPopupWeb.module.css';
import styles from './ManageAlerts/AlertSettings/AlertInput.module.css';
import { useEconCalendarParams } from '../../hooks/use-econ-calendar-params';
import { alertConfiguration, alertConfigurationMobile } from '../../utils/alertUtils';
import { useAlerts } from '../../hooks/useAlerts';
import CrossIconMobile from '../IconComponents/CrossIconMobile';
import { useTranslation } from 'react-i18next';

export default function AlertPopupWeb({ data }) {
  const [position, setPosition] = useState('bottom'); // Default to bottom
  const alertPopupRef = useRef(null);
  const { showAlertBox, setShowAlertBox, closeAlerts, handleSetAlert } = useAlerts();
  const { isIos, isAndroid } = useEconCalendarParams();
  const notWebsite = isIos || isAndroid;
  const { t } = useTranslation();

  // State to manage the input variables dynamically
  const [inputState, setInputState] = useState({
    alertPlatforms: [],
    alertRepeatitions: '',
  });

  // Function to update the state
  const updateValue = (category, option) => {
    setInputState((prevState) => {
      if (category === 'alertRepeatitions') {
        // Radio button: reset other options in the same category
        return {
          ...prevState,
          [category]: option,
        };
      }

      // Checkbox: toggle the current option
      const currentOptions = prevState[category] || [];
      const isOptionSelected = currentOptions.includes(option);

      return {
        ...prevState,
        [category]: isOptionSelected
          ? currentOptions.filter((item) => item !== option) // Remove the option
          : [...currentOptions, option], // Add the option
      };
    });
  };

  const configData = isAndroid || isIos ? alertConfigurationMobile : alertConfiguration;

  useEffect(() => {
    const handlePosition = () => {
      const alertPopup = alertPopupRef.current;
      const triggerElement = document.getElementById('alertPopup');
      const triggerRect = triggerElement?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - triggerRect.bottom;
      const spaceAbove = triggerRect.top - 300;

      if (spaceBelow < alertPopup.offsetHeight && spaceAbove > alertPopup.offsetHeight) {
        setPosition('top');
      } else {
        setPosition('bottom');
      }
    };

    const handleClickOutside = (event) => {
      const clickedElement = event.target;
      const alertPopUpBoxElement = clickedElement.closest('.alertPopup');
      if (
        showAlertBox &&
        alertPopupRef.current &&
        !alertPopupRef.current.contains(event.target) &&
        !alertPopUpBoxElement
      ) {
        setShowAlertBox(false);
      }
    };

    handlePosition();
    window.addEventListener('resize', handlePosition);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handlePosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAlertBox]);

  let enabled = inputState['alertPlatforms'].length && inputState['alertRepeatitions'];

  const resetFilters = () => {
    setInputState({
      alertPlatforms: [],
      alertRepeatitions: '',
    });
  };

  const handleSetAlertData = () => {
    enabled && handleSetAlert(data, inputState);
  };

  return (
    <>
      {notWebsite && <div className={style.modal}></div>}
      <div
        className={`${!notWebsite ? style.alertContainer : style.alertMobileContainer} ${
          !notWebsite ? (position === 'top' ? style.topPosition : style.bottomPosition) : style.none
        }`}
        id="alertPopup"
        ref={alertPopupRef}
      >
        {notWebsite && (
          <div
            className={`${style.alertsMobileHeadingWrapper} ${isAndroid ? style.androidHeader : style.none}`}
          >
            <div className={style.alertsMobileHeading}>
              {!isAndroid && (
                <button type="button" onClick={resetFilters}>
                  {t('Reset')}
                </button>
              )}
              <span className={isAndroid ? style.androidSetAlert : style.none}>
                {t('Set Alert')}
              </span>
            </div>
            <div onClick={closeAlerts}>
              <CrossIconMobile />
            </div>
          </div>
        )}

        {Object.entries(configData).map(([category, data]) => (
          <div key={category} className={notWebsite ? style.alertMeViaMobile : style.alertMeWeb}>
            <p
              className={`${notWebsite ? style.alertHeadMobile : style.alertHead} ${isAndroid ? style.alertAndroidHead : style.alertIosHead}`}
            >
              {t(data.text)}
            </p>
            <div
              className={`${notWebsite ? style.gapBetweenMobile : style.gapBetween} ${isIos ? style.iosGapBetweenMobile : style.none}`}
            >
              {data.options.map((option) => (
                <div
                  key={option[0]}
                  className={`${notWebsite ? style.alignCheckBoxLineMobile : style.alignCheckBoxLine} ${notWebsite ? (isIos ? styles.inputStylingMobile : styles.inputStylingAndroid) : styles.inputStyling} ${isAndroid ? style.checkBoxLineAndroid : style.none}`}
                >
                  <div className={notWebsite ? style.none : style.webInputWrapper}>
                    <input
                      type={category === 'alertRepeatitions' ? 'radio' : 'checkbox'}
                      id={option[0]}
                      name={category}
                      checked={
                        category === 'alertRepeatitions'
                          ? inputState[category] === option[0] // Radio button: match the single selected value
                          : inputState[category]?.includes(option[0]) // Checkbox: check if the option is in the array
                      }
                      onChange={() => updateValue(category, option[0])}
                    />
                  </div>
                  <label
                    htmlFor={option[0]}
                    className={notWebsite ? style.labelTextMobile : style.labelText}
                  >
                    <span>{t(option[0])}</span>
                    {!notWebsite && option[1] && (
                      <p className={style.recurringSpanBottom}>{t(option[1])}</p>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className={notWebsite ? style.buttonDivMobile : style.buttonDiv}>
          <div
            role="button"
            className={`${notWebsite ? style.setAlertButtonMobile : style.setAlertButton} ${enabled ? style.setAlertEnabled : style.setAlertDisabled}`}
            onClick={handleSetAlertData}
          >
            {t('Set Alert')}
          </div>
        </div>
      </div>
    </>
  );
}
