// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D6SxqSK9jjKtrkZsfkGi {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.FM9x0iZ6IBqUJVRnYXjH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--mobile-tabs-expanded-background-color);
  border-bottom: 0.5px solid var(--mobile-data-border-color);
  padding: 12px;
  height: 70px;
  color: var(--mobile-text-primary);
}

.rUZneo6Pzo1ESNmrzmtH {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.GuDD1q6FVTYTVA4TejFk {
  font-size: 14px;
  font-weight: bold;
  color: #e63946; /* Red color for importance */
  margin-right: 10px;
}

.Xxf1kt4AI4NC3Rig2aPn {
  font-size: 19px;
}

.Xxf1kt4AI4NC3Rig2aPn > img {
  width: 20px !important;
  height: 14px !important;
  border-radius: 3px;
}

.OmYynyCDx_gRBibWCblV {
  font-size: 16px;
  flex: 1;
}

.c62tbfHKGq8k7A7KgeFF {
  font-size: 14px;
  color: var(--mobile-text-secondary);
  margin-right: 12px;
}

.c62tbfHKGq8k7A7KgeFF > span {
  color: var(--ios-customDateBtn-text-color);
}

.g9Wjpe2btxHSSW3NcsPL {
  all: unset;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
}

.g9Wjpe2btxHSSW3NcsPL:hover {
  color: #b71c1c;
}

.jPVX_PqU2iJScsYmTSTM {
  display: flex;
  gap: 8px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alerts/ManageAlerts/BulkAlerts/BulkAlertMobileView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,8DAA8D;EAC9D,0DAA0D;EAC1D,aAAa;EACb,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc,EAAE,6BAA6B;EAC7C,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,OAAO;AACT;;AAEA;EACE,eAAe;EACf,mCAAmC;EACnC,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".alertList {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.alertItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--mobile-tabs-expanded-background-color);\n  border-bottom: 0.5px solid var(--mobile-data-border-color);\n  padding: 12px;\n  height: 70px;\n  color: var(--mobile-text-primary);\n}\n\n.bulkItemSpacing {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.importance {\n  font-size: 14px;\n  font-weight: bold;\n  color: #e63946; /* Red color for importance */\n  margin-right: 10px;\n}\n\n.flag {\n  font-size: 19px;\n}\n\n.flag > img {\n  width: 20px !important;\n  height: 14px !important;\n  border-radius: 3px;\n}\n\n.country {\n  font-size: 16px;\n  flex: 1;\n}\n\n.endDate {\n  font-size: 14px;\n  color: var(--mobile-text-secondary);\n  margin-right: 12px;\n}\n\n.endDate > span {\n  color: var(--ios-customDateBtn-text-color);\n}\n\n.deleteButton {\n  all: unset;\n  background: none;\n  border: none;\n  font-size: 18px;\n  cursor: pointer;\n  display: flex;\n}\n\n.deleteButton:hover {\n  color: #b71c1c;\n}\n\n.rowItem {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertList": `D6SxqSK9jjKtrkZsfkGi`,
	"alertItem": `FM9x0iZ6IBqUJVRnYXjH`,
	"bulkItemSpacing": `rUZneo6Pzo1ESNmrzmtH`,
	"importance": `GuDD1q6FVTYTVA4TejFk`,
	"flag": `Xxf1kt4AI4NC3Rig2aPn`,
	"country": `OmYynyCDx_gRBibWCblV`,
	"endDate": `c62tbfHKGq8k7A7KgeFF`,
	"deleteButton": `g9Wjpe2btxHSSW3NcsPL`,
	"rowItem": `jPVX_PqU2iJScsYmTSTM`
};
export default ___CSS_LOADER_EXPORT___;
