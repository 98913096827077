import { useTranslation } from 'react-i18next';
import style from './BulkAlertSettingsTableData.module.css';
import DeleteIconAlerts from '../../../../assets/svgs/DeleteIconAlerts';
import { convertDateToString, searchCountryCode } from '../../../../utils/util';
import { useEconCalendarParams } from '../../../../hooks/use-econ-calendar-params';
import { useSessionData } from '../../../../hooks/use-session-data';
import { useAlerts } from '../../../../hooks/useAlerts';
import { FlagIconCode } from '../../../../types/flagIconCode';
import { FlagIcon } from 'react-flag-kit';
import ImportanceBadge from '../../../Global/ImportanceBadge';

const BulkAlertSettingsTableData = ({ data, rowsInfo, selectedAlertType }) => {
  const { t } = useTranslation();
  const { locale } = useEconCalendarParams();
  const { timezoneOffset } = useSessionData();
  const { handleDeleteEconBulkAlertsData } = useAlerts();

  const handleDelete = (data) => {
    handleDeleteEconBulkAlertsData(data, selectedAlertType);
  };

  const rowKeys = Object.keys(rowsInfo);
  const rowValues = Object.values(rowsInfo);

  const selectedTabData = data?.[selectedAlertType];

  const getFlagCode = (country) => {
    return searchCountryCode(country) as FlagIconCode;
  };

  const sortData = () => {
    selectedTabData?.sort((a, b) => {
      const dateA = new Date(a?.endDate?.[0], a?.endDate?.[1] - 1, a?.endDate?.[2]); // Month is 0-based
      const dateB = new Date(b?.endDate?.[0], b?.endDate?.[1] - 1, b?.endDate?.[2]);

      return dateA.getTime() - dateB.getTime(); // Sort in ascending order
    });
  };

  sortData();

  return (
    <div className={style.tableContainer}>
      <table className={style.customTable}>
        <thead className={style.tableHead}>
          <tr className={style.tableRow}>
            {rowValues && rowValues.map((row, index) => <th key={index}>{row}</th>)}
          </tr>
        </thead>
        <tbody>
          {selectedAlertType === 'macroeconomicEvents'
            ? selectedTabData &&
              selectedTabData.length > 0 &&
              selectedTabData.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {<FlagIcon code={getFlagCode(row[rowKeys[0]]?.join(', '))} loading="lazy" />}
                      {t(row[rowKeys[0]]?.join(', ')) || '-'}
                    </td>
                    <td
                      className={
                        row[rowKeys[1]]?.length > 1
                          ? style.multipleImportance
                          : style.singleImportance
                      }
                    >
                      {row[rowKeys[1]]?.map((value, index) => (
                        <ImportanceBadge
                          text={value}
                          t={t}
                          key={index}
                          bulkAlerts={row[rowKeys[1]]?.length > 1 ? false : true}
                        />
                      ))}
                    </td>
                    <td>
                      {row?.endDate && row?.endDate.length > 0
                        ? convertDateToString(
                            row?.endDate.join('-'),
                            timezoneOffset,
                            true,
                            locale != 'en' ? locale : undefined,
                            t,
                            true,
                          )
                        : '-'}
                    </td>
                    <td>
                      {row?.lastTriggered
                        ? convertDateToString(
                            row[rowKeys[2]],
                            timezoneOffset,
                            true,
                            locale != 'en' ? locale : undefined,
                            t,
                            true,
                          )
                        : '-'}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(row)} className={style.deleteIcon}>
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </tr>
                );
              })
            : selectedTabData &&
              selectedTabData.length > 0 &&
              selectedTabData.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row[rowKeys[0]] || '-'}</td>
                    <td>
                      {row?.endDate && row?.endDate.length > 0
                        ? convertDateToString(
                            row?.endDate.join('-'),
                            timezoneOffset,
                            true,
                            locale != 'en' ? locale : undefined,
                            t,
                            true,
                          )
                        : '-'}
                    </td>
                    <td>
                      {row?.[rowKeys?.[2]]
                        ? convertDateToString(
                            row[rowKeys[2]],
                            timezoneOffset,
                            true,
                            locale != 'en' ? locale : undefined,
                            t,
                            true,
                          )
                        : '-'}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(row)} className={style.deleteIcon}>
                        <DeleteIconAlerts />
                      </button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default BulkAlertSettingsTableData;
